import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import { StakingContract, MemoTokenContract, TimeTokenContract, Erc20Contract } from "../../abi";
import { setAll } from "../../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider } from "@ethersproject/providers";
import { getMarketPrice, getTokenPrice } from "../../helpers";
import { RootState } from "../store";
import allBonds from "../../helpers/bond";
import treasury from "../../helpers/treasury";

interface ILoadAppDetails {
    networkID: number;
    provider: JsonRpcProvider;
}

const IndexOffset = 4547419269; // sTOKEN starting index

export const loadAppDetails = createAsyncThunk(
    "app/loadAppDetails",
    //@ts-ignore
    async ({ networkID, provider }: ILoadAppDetails) => {
        const daiPrice = getTokenPrice("DAI");
        const addresses = getAddresses(networkID);

        const stakingContract = new ethers.Contract(addresses.STAKING_ADDRESS, StakingContract, provider);
        const currentBlock = await provider.getBlockNumber();
        const currentBlockTime = (await provider.getBlock(currentBlock)).timestamp;
        const tokenContract = new ethers.Contract(addresses.TOKEN_ADDRESS, TimeTokenContract, provider);
        const sTokenContract = new ethers.Contract(addresses.STOKEN_ADDRESS, MemoTokenContract, provider);

        const tokenAmount = 0; // Initial DAI deposit
        let [marketPrice, totalSupply, circSupply] = await Promise.all([getMarketPrice(networkID, provider), tokenContract.totalSupply(), sTokenContract.circulatingSupply()]);
        marketPrice = marketPrice * daiPrice;
        totalSupply = totalSupply / Math.pow(10, 9);
        circSupply = circSupply / Math.pow(10, 9);

        const stakingTVL = circSupply * marketPrice;
        const marketCap = totalSupply * marketPrice;

        // Treasury (for isolated/unused tokens)
        // const treasuryBalances = await treasury.getTreasuryBalances(networkID, provider);
        // const treasuryBalance = treasuryBalances.map(bal => bal.balance).reduce((a, b) => a + b);
        const treasuryBalances: any[] = [];

        // Bonds
        const bondTreasuryBalPromises = allBonds.map(bond => bond.getTreasuryBalance(networkID, provider));
        const tokenAmountsPromises = allBonds.map(bond => bond.getTokenAmount(networkID, provider));
        const tokenBondsAmountsPromises = allBonds.map(bond => bond.getTimeAmount(networkID, provider));
        const bondResults = await Promise.all([bondTreasuryBalPromises, tokenAmountsPromises, tokenBondsAmountsPromises].flat());

        const bondTreasuryBalances = bondResults.splice(0, allBonds.length);
        const bondTreasuryBalance = bondTreasuryBalances.reduce((tokenBalance0, tokenBalance1) => tokenBalance0 + tokenBalance1, tokenAmount);

        const bondBalance = bondTreasuryBalance;

        const tokenAmounts = bondResults.splice(0, allBonds.length);
        const rfvTreasury = tokenAmounts.reduce((tokenAmount0, tokenAmount1) => tokenAmount0 + tokenAmount1, tokenAmount);

        const timeBondsAmounts = bondResults.splice(0, allBonds.length);
        const timeAmount = timeBondsAmounts.reduce((timeAmount0, timeAmount1) => timeAmount0 + timeAmount1, 0);
        const timeSupply = totalSupply - timeAmount;

        const rfv = rfvTreasury / timeSupply;

        const [epoch, circ, currentIndex] = await Promise.all([stakingContract.epoch(), sTokenContract.circulatingSupply(), stakingContract.index()]);
        const stakingReward = epoch.distribute;
        const stakingRebase = stakingReward / circ;
        const fiveDayRate = Math.pow(1 + stakingRebase, 5 * 3) - 1;
        const stakingAPY = Math.pow(1 + stakingRebase, 365 * 3) - 1;
        const stakedSupply = (circSupply / totalSupply) * 100;
        const nextRebase = epoch.endTime;

        const treasuryRunway = rfvTreasury / circSupply;
        const runway = Math.log(treasuryRunway) / Math.log(1 + stakingRebase) / 3;

        return {
            currentIndex: currentIndex / IndexOffset,
            totalSupply,
            marketCap,
            currentBlock,
            circSupply,
            fiveDayRate,
            treasuryBalance: bondBalance,
            treasuryAssets: treasuryBalances,
            stakingAPY,
            stakingTVL,
            stakingRebase,
            marketPrice,
            currentBlockTime,
            nextRebase,
            rfv,
            runway,
            stakedSupply,
        };
    },
);

const initialState = {
    loading: true,
};

export interface IAppSlice {
    loading: boolean;
    stakingTVL: number;
    marketPrice: number;
    marketCap: number;
    circSupply: number;
    currentIndex: string;
    currentBlock: number;
    currentBlockTime: number;
    fiveDayRate: number;
    treasuryBalance: number;
    treasuryAssets: {
        name: string;
        displayName: string;
        balance: number;
    }[];
    stakingAPY: number;
    stakingRebase: number;
    networkID: number;
    nextRebase: number;
    totalSupply: number;
    rfv: number;
    runway: number;
    stakedSupply: number;
}

const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        fetchAppSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAppDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(loadAppDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAppDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            });
    },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
