import { priceUnits, trim } from "../../helpers";
import BondLogo from "../../components/BondLogo";
import { Paper, TableRow, TableCell, Link } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { NavLink } from "react-router-dom";
import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import { IAllBondData } from "../../hooks/bonds";

interface IBondProps {
    bond: IAllBondData;
}

export function BondDataCard({ bond }: IBondProps) {
    const isBondLoading = !bond.bondPrice ?? true;

    return (
        // <Slide direction="up" in={true}>
        <Paper className="bond-data-card">
            <div className="bond-pair">
                <BondLogo bond={bond} />
                <div className="bond-name">
                    <p className="bond-name-title">
                        {bond.displayName}
                        {bond.isLP && (
                            <Link href={bond.lpUrl} target="_blank">
                                <OpenInNewIcon htmlColor="#C4C4C6" />
                            </Link>
                        )}
                    </p>
                    {/* <p className="bond-name-title">{bond.displayName}</p>
                    {bond.isLP && (
                        <div>
                            <Link href={bond.lpUrl} target="_blank">
                                <p className="bond-name-title">View Contract</p>
                            </Link>
                        </div>
                    )} */}
                </div>
            </div>

            <div className="data-row">
                <p className="bond-name-title">Price</p>
                <p className="bond-price bond-name-title">
                    <>
                        {/* {priceUnits(bond)} {isBondLoading ? <Skeleton width="50px" /> : trim(bond.bondPrice, 2)} */}
                        {isBondLoading ? (
                            <Skeleton width="50px" />
                        ) : !bond.soldOut ? (
                            new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 0,
                            }).format(bond.bondPrice)
                        ) : (
                            "-"
                        )}
                    </>
                </p>
            </div>

            <div className="data-row">
                <p className="bond-name-title">ROI</p>
                <p className="bond-name-title">{isBondLoading ? <Skeleton width="50px" /> : bond.soldOut ? "-" : `${trim(bond.bondDiscount * 100, 2)}%`}</p>
            </div>

            <div className="data-row">
                <p className="bond-name-title">TVL</p>
                <p className="bond-name-title">
                    {isBondLoading ? (
                        <Skeleton width="80px" />
                    ) : (
                        new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                        }).format(bond.purchased)
                    )}
                </p>
            </div>
            {/* <Link component={NavLink} to={`/mints/${bond.name}`}>
                <div className="bond-table-btn">
                    <p>Mint {bond.displayName}</p>
                </div>
            </Link> */}
            <div className="bond-table-btn-container">
                <div className="bond-table-btn-subcontainer">
                    <Link component={NavLink} to={!bond.soldOut ? `/mints/${bond.name}/mint` : "#"}>
                        <div className={!bond.soldOut ? "bond-table-btn" : "bond-table-btn-disable"}>
                            <p>{!bond.soldOut ? "Mint" : "Sold Out"}</p>
                        </div>
                    </Link>
                </div>
                <div className="bond-table-btn-subcontainer">
                    <Link component={NavLink} to={`/mints/${bond.name}/redeem`}>
                        <div className="bond-table-btn">
                            <p>Redeem</p>
                        </div>
                    </Link>
                </div>
            </div>
        </Paper>
        // </Slide>
    );
}

export function BondDataCardRedeemAll() {
    return (
        <div className="bond-table-btn-container">
            <div className="bond-table-btn-subcontainer">
                <Link component={NavLink} to={`/mints/redeemAll`}>
                    <div className="bond-table-btn">
                        <p>Redeem All</p>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export function BondTableData({ bond }: IBondProps) {
    const isBondLoading = bond.bondPrice === undefined; // bondPrice can be zero

    return (
        <TableRow>
            <TableCell align="left">
                <BondLogo bond={bond} />
                <div className="bond-name">
                    <p className="bond-name-title">
                        {bond.displayName}
                        {bond.isLP && (
                            <Link href={bond.lpUrl} target="_blank">
                                <OpenInNewIcon htmlColor="#C4C4C6" />
                            </Link>
                        )}
                    </p>
                    {/* {bond.isLP && (
                        <Link color="primary" href={bond.lpUrl} target="_blank">
                            <p className="bond-name-title">View Contract</p>
                            <OpenInNewIcon />
                        </Link>
                    )} */}
                </div>
            </TableCell>
            <TableCell align="center">
                <p className="bond-name-title">
                    {/* <>
                        <span className="currency-icon">$</span> {isBondLoading ? <Skeleton width="50px" /> : trim(bond.bondPrice, 2)}
                    </> */}
                    {isBondLoading ? (
                        <Skeleton width="50px" />
                    ) : !bond.soldOut ? (
                        new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 0,
                        }).format(bond.bondPrice)
                    ) : (
                        "-"
                    )}
                </p>
            </TableCell>
            <TableCell align="right">
                <p className="bond-name-title">{isBondLoading ? <Skeleton width="50px" /> : bond.soldOut ? "-" : `${trim(bond.bondDiscount * 100, 2)}%`}</p>
            </TableCell>
            <TableCell align="right">
                <p className="bond-name-title">
                    {isBondLoading ? (
                        <Skeleton width="50px" />
                    ) : (
                        new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                        }).format(bond.purchased)
                    )}
                </p>
            </TableCell>
            <TableCell>
                <div className="bond-table-btn-container">
                    <div className="bond-table-btn-subcontainer">
                        <Link component={NavLink} to={!bond.soldOut ? `/mints/${bond.name}/mint` : "#"}>
                            <div className={!bond.soldOut ? "bond-table-btn" : "bond-table-btn-disable"}>
                                <p>{!bond.soldOut ? "Mint" : "Sold Out"}</p>
                            </div>
                        </Link>
                    </div>
                    <div className="bond-table-btn-subcontainer">
                        <Link component={NavLink} to={`/mints/${bond.name}/redeem`}>
                            <div className="bond-table-btn">
                                <p>Redeem</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
}

export function BondTableRedeemAll() {
    return (
        <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
                <div className="bond-table-btn-container">
                    <div className="bond-table-btn-subcontainer">
                        <Link component={NavLink} to={`/mints/redeemAll`}>
                            <div className="bond-table-btn">
                                <p>Redeem All</p>
                            </div>
                        </Link>
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
}
