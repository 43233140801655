import { ContractInterface, utils } from "ethers";
import { MerkleTree } from "merkletreejs";
import keccak256 from "keccak256";
const { getAddress, solidityKeccak256 } = utils;

export interface RewardSnapshot {
    readonly decimals: number;
    readonly airdrop: { [key: string]: string };
}

export interface RewardOpts {
    readonly id: string; // Internal name used for references
    readonly symbol: string; // Symbol of reward token
    readonly icon0Img: string;
    readonly snapshot: RewardSnapshot; // Snapshot for distributor contract
}

export class Reward {
    public readonly id: string;
    public readonly symbol: string;
    public readonly icon0Img: string;
    public readonly snapshot: RewardSnapshot;
    public readonly merkleTree: MerkleTree;
    public readonly merkleRoot: string;

    constructor(rewardOpts: RewardOpts) {
        this.id = rewardOpts.id;
        this.symbol = rewardOpts.symbol;
        this.icon0Img = rewardOpts.icon0Img;
        this.snapshot = rewardOpts.snapshot;
        this.merkleTree = this.generateTree();
        this.merkleRoot = this.merkleTree.getHexRoot();
    }

    public getDecimals(): number {
        return Number(this.snapshot.decimals);
    }

    public getAllocation(address: string): string {
        return this.snapshot.airdrop[address] || "0";
    }

    public generateMerkleProof(address: string, value: string): string[] {
        const formattedAddress = getAddress(address);
        const leaf = this.generateLeaf(formattedAddress, value);
        return this.merkleTree.getHexProof(leaf);
    }

    private generateLeaf(address: string, value: string): Buffer {
        return Buffer.from(solidityKeccak256(["address", "uint256"], [address, value]).slice(2), "hex");
    }

    private generateTree(): MerkleTree {
        const { airdrop } = this.snapshot;
        const recipients = Object.entries(airdrop).map(([address, tokens], _) => {
            return {
                address: getAddress(address),
                value: tokens,
            };
        });
        const leafs = recipients.map(({ address, value }) => this.generateLeaf(address, value));
        return new MerkleTree(leafs, keccak256, { sortPairs: true });
    }
}
