import { Networks } from "./blockchain";

const AVAX_MAINNET = {
    DAO_ADDRESS: "",
    TIME_ADDRESS: "",
    MEMO_ADDRESS: "",
    TOKEN_ADDRESS: "",
    STOKEN_ADDRESS: "",
    WSTOKEN_ADDRESS: "",
    MIM_ADDRESS: "",
    STAKING_ADDRESS: "",
    STAKING_HELPER_ADDRESS: "",
    REDEEM_HELPER_ADDRESS: "",
    TIME_BONDING_CALC_ADDRESS: "",
    TREASURY_ADDRESS: "",
    TREASURY_REDEMPTION_ADDRESS: "",
    ZAPIN_ADDRESS: "",
};

const AVAX_TESTNET = {
    DAO_ADDRESS: "",
    TIME_ADDRESS: "",
    MEMO_ADDRESS: "",
    TOKEN_ADDRESS: "",
    STOKEN_ADDRESS: "",
    WSTOKEN_ADDRESS: "",
    MIM_ADDRESS: "",
    STAKING_ADDRESS: "",
    STAKING_HELPER_ADDRESS: "",
    REDEEM_HELPER_ADDRESS: "",
    TIME_BONDING_CALC_ADDRESS: "",
    TREASURY_ADDRESS: "",
    TREASURY_REDEMPTION_ADDRESS: "",
    ZAPIN_ADDRESS: "",
};

const PLS_TESTNET = {
    DAO_ADDRESS: "0xB94863d8b449468C2f7E60a4a0A420A40Bc7935d",
    TIME_ADDRESS: "0xC1963d3F1e6E2B71f87A8FdE6190c33bDb25dB34", // TOKEN
    MEMO_ADDRESS: "0x62862850A422d7607BC9dC4591e197fC594EdA15", // sTOKEN
    TOKEN_ADDRESS: "0xC1963d3F1e6E2B71f87A8FdE6190c33bDb25dB34", // TOKEN
    STOKEN_ADDRESS: "0x62862850A422d7607BC9dC4591e197fC594EdA15", // sTOKEN
    WSTOKEN_ADDRESS: "0x247Bb7848662e93c2eC53995bF6971B9a2CA4705", // wsTOKEN
    STAKING_ADDRESS: "0x674c726b122a1e6bff9eebcfb20a6D60601f749d",
    STAKING_HELPER_ADDRESS: "0xcDB9a0ce0db1a808adD4A73Ff5dAE63ED7d1302d",
    REDEEM_HELPER_ADDRESS: "",
    TIME_BONDING_CALC_ADDRESS: "0x28bB912a7d17b287bc054dCebDD857e92bC8130c",
    TREASURY_ADDRESS: "0x9a1495e3b5783453B4cB8b5e45f09fB68F2BE568",
    TREASURY_REDEMPTION_ADDRESS: "",
    ZAPIN_ADDRESS: "",
};

const PLS_MAINNET = {
    DAO_ADDRESS: "0xB94863d8b449468C2f7E60a4a0A420A40Bc7935d",
    TIME_ADDRESS: "0xBB366A397D7d4d2BEDABD9139D4c32a8826605Ed", // TOKEN
    MEMO_ADDRESS: "0xe0561d2D757663F1fc15A81d86E908bd37BfB6c9", // sTOKEN
    TOKEN_ADDRESS: "0xBB366A397D7d4d2BEDABD9139D4c32a8826605Ed", // TOKEN
    STOKEN_ADDRESS: "0xe0561d2D757663F1fc15A81d86E908bd37BfB6c9", // sTOKEN
    WSTOKEN_ADDRESS: "0xD1Bc8cdEc47d9769D6e887BB1D2e0a3b9ec88282", // wsTOKEN
    STAKING_ADDRESS: "0xf1Bc988e7EaBA7a2dbF0121E6ad9BEA82A1AB1ff",
    STAKING_HELPER_ADDRESS: "0xBa3FBd40BB6B9B248C1b3f86926F97672a51315f",
    REDEEM_HELPER_ADDRESS: "",
    TIME_BONDING_CALC_ADDRESS: "0x4C65D48D0fAEE8D4ec743CBa83b42b6E3cD85cec",
    TREASURY_ADDRESS: "0x767d028d6d49ac86Aba52d23746c6dC5285C4852",
    TREASURY_REDEMPTION_ADDRESS: "0xE5E9e882df99a53B948bB3Ed44948dD580Ee7859",
    ZAPIN_ADDRESS: "",
};

export interface Token {
    label: string;
    symbol: string;
    address: string;
}

export interface Bond extends Token {
    bondToken: string;
}

export const LP_TOKENS: { [key: string]: Token } = {
    SEX_DAI: { label: "sex_dai_plp", symbol: "SEX-DAI PLP", address: "0x2d593b3472d6a5439bC1523a04C2aec314CBc44c" },
};

export const TOKENS: { [key: string]: Token } = {
    SEX: { label: "sex", symbol: "SEX", address: "0xBB366A397D7d4d2BEDABD9139D4c32a8826605Ed" },
    SSEX: { label: "ssex", symbol: "sSEX", address: "0xe0561d2D757663F1fc15A81d86E908bd37BfB6c9" },
    WSSEX: { label: "wssex", symbol: "wsSEX", address: "0xD1Bc8cdEc47d9769D6e887BB1D2e0a3b9ec88282" },
    WPLS: { label: "wpls", symbol: "WPLS", address: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27" },
    DAI: { label: "dai", symbol: "DAI", address: "0xefD766cCb38EaF1dfd701853BFCe31359239F305" },
    USDC: { label: "usdc", symbol: "USDC", address: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07" },
    USDT: { label: "usdt", symbol: "USDT", address: "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f" },
};

export const BONDS: { [key: string]: Token } = {
    SEX_DAI: { label: "sex_dai_plp", symbol: "SEX-DAI PLP", address: "0x061E8dC66184316B29Cf32901e289dB942467Ad0" },
    DAI: { label: "dai", symbol: "DAI", address: "0x51736Be4Ec27010787061359b5A185F258629164" },
    USDC: { label: "usdc", symbol: "USDC", address: "0x2B913F5C618505015F56F2aE67689280A0319bBf" },
    USDT: { label: "usdt", symbol: "USDT", address: "0x8B74C21d0261dA8b68280B063Cf4b4f92827f8a5" },
};

// // Testnet
// export const LP_TOKENS: { [key: string]: Token } = {
//     SEX_DAI: { label: "sex_dai_plp", symbol: "SEX-DAI PLP", address: "0x6E0296Ca8f92AfEd04397d9C60d9461bFeD8BcAA" },
// };

// export const TOKENS: { [key: string]: Token } = {
//     SEX: { label: "sex", symbol: "SEX", address: "0xC1963d3F1e6E2B71f87A8FdE6190c33bDb25dB34" },
//     SSEX: { label: "ssex", symbol: "sSEX", address: "0x62862850A422d7607BC9dC4591e197fC594EdA15" },
//     WSSEX: { label: "wssex", symbol: "wsSEX", address: "0x247Bb7848662e93c2eC53995bF6971B9a2CA4705" },
//     WPLS: { label: "wpls", symbol: "WPLS", address: "0x70499adebb11efd915e3b69e700c331778628707" },
//     DAI: { label: "dai", symbol: "DAI", address: "0xB03C476119D12551FFaA2fFfd32A0041Ec41649F" },
//     USDC: { label: "usdc", symbol: "USDC", address: "0x9F4C4647DdC463F2224a8eeFB13A8fEab39999a9" },
//     USDT: { label: "usdt", symbol: "USDT", address: "0xfBA17528F8cCD828Aa3422fc053dCA22cdD023FA" },
// };

// export const BONDS: { [key: string]: Token } = {
//     SEX_DAI: { label: "sex_dai_plp", symbol: "SEX-DAI PLP", address: "0x73ea81D67C7B2EEad2b0978ecC73Ab6991Dc2B8b" },
//     DAI: { label: "dai", symbol: "DAI", address: "0x7857B2b30fE46F811264E70823a65F43336F20A5" },
//     USDC: { label: "usdc", symbol: "USDC", address: "0x33e435fE24Dbc18652f50D7187A22DBd97632D95" },
//     USDT: { label: "usdt", symbol: "USDT", address: "0xA5d87B9B3a510dbDB5737dc1B745928E3db7C9d7" },
// };

export const getAddresses = (networkID: number) => {
    if (networkID === Networks.AVAX) return AVAX_MAINNET;
    if (networkID === Networks.AVAX_TESTNET) return AVAX_TESTNET;
    if (networkID === Networks.PULSECHAIN_TESTNET) return PLS_TESTNET;
    if (networkID === Networks.PULSECHAIN) return PLS_MAINNET;
    console.log("networkID", networkID);

    throw Error("Network not supported");
};

export const TOKEN_DECIMALS = 9;
