import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { changeRedeemAllApproval, redeemAllBonds } from "../../store/slices/bond-slice";
import { useWeb3Context } from "../../hooks";
import { trim } from "../../helpers";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { IAllBondData } from "../../hooks/bonds";
import { messages } from "../../constants/messages";
import { warning } from "../../store/slices/messages-slice";
import { useCallback } from "react";
import { TOKEN_SYMBOL } from "src/constants";

interface IBondRedeem {
    bonds: IAllBondData[];
}

function BondRedeem({ bonds }: IBondRedeem) {
    const dispatch = useDispatch();
    const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();
    const history = useHistory();

    const isBondLoading = useSelector<IReduxState, boolean>(state => state.bonding.loading ?? true);
    const claimableBonds: IAllBondData[] = bonds.filter(bond => bond.pendingPayout !== 0);
    const claimableToken: number = claimableBonds.length > 0 ? claimableBonds.map(bond => bond.pendingPayout).reduce((a, b) => a + b) : 0;

    const redeemAllAllowance = useSelector<IReduxState, number>(state => {
        return state.account.redeemAll && state.account.redeemAll.token;
    });
    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    async function onRedeem(autostake: boolean) {
        if (await checkWrongNetwork()) return;

        if (claimableToken === 0) {
            dispatch(warning({ text: messages.nothing_to_claim }));
            return;
        }

        await dispatch(redeemAllBonds({ address, bonds: claimableBonds, networkID: chainID, provider, autostake }));
    }

    const onSeekApproval = async () => {
        if (await checkWrongNetwork()) return;

        dispatch(changeRedeemAllApproval({ address, provider, networkID: chainID }));
    };

    const hasAllowance = useCallback(() => {
        return redeemAllAllowance > 0;
    }, [redeemAllAllowance]);

    const handleConnect = () => {
        history.push("/mints");
        setTimeout(() => connect(), 100);
    };

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="space-around" flexWrap="wrap">
                {!address && (
                    <div className="transaction-button bond-approve-btn" onClick={handleConnect}>
                        <p className="stake-card-wallet-desc-text">Connect Wallet</p>
                    </div>
                )}
                {address && hasAllowance() && (
                    <div
                        className="transaction-button bond-approve-btn"
                        onClick={() => {
                            if (isPendingTxn(pendingTransactions, "redeem_all_bonds_autostake")) return;
                            onRedeem(true);
                        }}
                    >
                        <p>{txnButtonText(pendingTransactions, "redeem_all_bonds_autostake", "Claim and Autostake All")}</p>
                    </div>
                )}
                {address && !hasAllowance() && (
                    <div
                        className="transaction-button bond-approve-btn"
                        onClick={() => {
                            if (isPendingTxn(pendingTransactions, "approve_redeem_all_bonds")) return;
                            onSeekApproval();
                        }}
                    >
                        <p>{txnButtonText(pendingTransactions, "approve_redeem_all_bonds", "Approve")}</p>
                    </div>
                )}
            </Box>

            <Box className="bond-data">
                <div className="data-row">
                    <p className="bond-balance-title"></p>
                    <p className="price-data bond-balance-value">Claimable Rewards</p>
                </div>
                {claimableBonds &&
                    claimableBonds.map(bond => {
                        return (
                            <div className="data-row" key={`redeem-all-${bond.displayName}`}>
                                <p className="bond-balance-title">{bond.displayName}</p>
                                <p className="price-data bond-balance-value">{isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.pendingPayout, 9)} ${TOKEN_SYMBOL}`}</p>
                            </div>
                        );
                    })}
            </Box>
        </Box>
    );
}

export default BondRedeem;
