import { DEFAULT_NETWORK } from "../../constants/blockchain";
import { MerkleDistributorContract } from "../../abi";
import { TokenSnapshot, DaiSnapshot, UsdcSnapshot, WavaxSnapshot } from "../../snapshots";
import TokenIcon from "../../assets/tokens/PROJECT.png";
import DaiIcon from "../../assets/tokens/DAI.png";
import UsdcIcon from "../../assets/tokens/USDC.png";
import UsdtIcon from "../../assets/tokens/USDT.png";
// import WavaxIcon from "../../assets/tokens/WAVAX.png";
import { Distributor } from "./distributor";
import { Reward } from "./reward";
import { getAddresses } from "../../constants/addresses";
import { TOKEN_SYMBOL } from "src/constants";

// Snapshot: x
export const distributor = new Distributor({
    id: "distributor",
    distributorContractABI: MerkleDistributorContract,
    networkAddrs: {
        [DEFAULT_NETWORK]: {
            distributorAddress: getAddresses(DEFAULT_NETWORK).TREASURY_REDEMPTION_ADDRESS,
        },
    },
});

const token = new Reward({
    id: "token",
    symbol: TOKEN_SYMBOL,
    icon0Img: TokenIcon,
    snapshot: TokenSnapshot,
});

const dai = new Reward({
    id: "dai",
    symbol: "DAI",
    icon0Img: DaiIcon,
    snapshot: DaiSnapshot,
});

const usdc = new Reward({
    id: "usdc",
    symbol: "USDC",
    icon0Img: UsdcIcon,
    snapshot: UsdcSnapshot,
});

const wavax = new Reward({
    id: "wavax",
    symbol: "WAVAX",
    // icon0Img: WavaxIcon,
    icon0Img: UsdtIcon,
    snapshot: WavaxSnapshot,
});

export const snapshot = {
    token,
    dai,
    // usdc,
    // wavax,
};
export default distributor;
