import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { trim } from "../../helpers";
import { Grid, Backdrop, Paper, Box, Tab, Tabs, Fade } from "@material-ui/core";
import TabPanel from "../../components/TabPanel";
import BondHeader from "./BondHeader";
import BondRedeem from "./BondRedeem";
import "../Bond/bond.scss";
import { useWeb3Context } from "../../hooks";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { IAllBondData } from "../../hooks/bonds";
import classnames from "classnames";
import { TOKEN_SYMBOL } from "src/constants";

interface IBondsProps {
    bonds: IAllBondData[];
}

function BondAll({ bonds }: IBondsProps) {
    const { provider, address } = useWeb3Context();

    const [slippage, setSlippage] = useState(0.5);
    const [recipientAddress, setRecipientAddress] = useState(address);
    const [claimableToken, setClaimableToken] = useState(0);
    const [pendingToken, setPendingToken] = useState(0);

    const isBondLoading = useSelector<IReduxState, boolean>(state => state.bonding.loading ?? true);

    const onRecipientAddressChange = (value: any) => {
        return setRecipientAddress(value);
    };

    const onSlippageChange = (value: any) => {
        return setSlippage(value);
    };

    useEffect(() => {
        if (address) setRecipientAddress(address);
    }, [provider, address]);

    useEffect(() => {
        if (bonds) {
            let totalClaimable = 0;
            let totalPending = 0;
            bonds.forEach(bond => {
                totalClaimable += bond.pendingPayout;
                totalPending += bond.interestDue;
            });
            setClaimableToken(totalClaimable);
            setPendingToken(totalPending);
        }
    }, [bonds]);

    return (
        <Fade in={true} mountOnEnter unmountOnExit>
            <Grid className="bond-view">
                <Backdrop open={true}>
                    <Fade in={true}>
                        <div className="bond-card">
                            <BondHeader
                                slippage={slippage}
                                recipientAddress={recipientAddress}
                                onSlippageChange={onSlippageChange}
                                onRecipientAddressChange={onRecipientAddressChange}
                            />
                            {/* @ts-ignore */}
                            <Box direction="row" className="bond-price-data-row">
                                <div className="bond-price-data">
                                    <p className="bond-price-data-title">Claimable {TOKEN_SYMBOL}</p>
                                    <p className="bond-price-data-value">{isBondLoading ? <Skeleton /> : `${trim(claimableToken, 4)} ${TOKEN_SYMBOL}`}</p>
                                </div>
                                <div className="bond-price-data">
                                    <p className="bond-price-data-title">Pending {TOKEN_SYMBOL}</p>
                                    <p className="bond-price-data-value">{isBondLoading ? <Skeleton /> : `${trim(pendingToken, 4)} ${TOKEN_SYMBOL}`}</p>
                                </div>
                            </Box>

                            <br />

                            <BondRedeem bonds={bonds} />
                        </div>
                    </Fade>
                </Backdrop>
            </Grid>
        </Fade>
    );
}

export default BondAll;
