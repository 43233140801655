import PlsIcon from "../assets/tokens/PLS.png";
import DaiIcon from "../assets/tokens/DAI.png";
import SexIcon from "../assets/tokens/SEX.png";
import { TOKENS } from "src/constants";

export interface IToken {
    name: string;
    address: string;
    img: string;
    isAvax?: boolean;
    decimals: number;
}

// export const avax: IToken = {
//     name: "AVAX",
//     isAvax: true,
//     img: AvaxIcon,
//     address: "",
//     decimals: 18,
// };

const pls: IToken = {
    name: "PLS",
    address: "",
    img: PlsIcon,
    decimals: 18,
    isAvax: true,
};

const wpls: IToken = {
    name: TOKENS.WPLS.symbol,
    address: TOKENS.WPLS.address,
    img: PlsIcon,
    decimals: 18,
};

const dai: IToken = {
    name: TOKENS.DAI.symbol,
    address: TOKENS.DAI.address,
    img: DaiIcon,
    decimals: 18,
};

const token: IToken = {
    name: TOKENS.SEX.symbol,
    address: TOKENS.SEX.address,
    img: SexIcon,
    decimals: 9,
};

const sToken: IToken = {
    name: TOKENS.SSEX.symbol,
    address: TOKENS.SSEX.address,
    img: SexIcon,
    decimals: 9,
};

const wsToken: IToken = {
    name: TOKENS.WSSEX.symbol,
    address: TOKENS.WSSEX.address,
    img: SexIcon,
    decimals: 9,
};

export default [dai, token, sToken];
