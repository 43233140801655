import React, { useEffect, useState } from "react";
import "./calculator.scss";
import { useSelector } from "react-redux";
import { Grid, InputAdornment, OutlinedInput, Zoom, Slider } from "@material-ui/core";
import { IReduxState } from "../../store/slices/state.interface";
import { getMarketCap, loadMarketCap, trim } from "../../helpers";
import { Skeleton } from "@material-ui/lab";
import { STOKEN_SYMBOL, TOKEN_SYMBOL } from "src/constants";

function Calculator() {
    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const marketPrice = useSelector<IReduxState, number>(state => {
        return state.app.marketPrice;
    });
    const stakingAPY = useSelector<IReduxState, number>(state => {
        return state.app.stakingAPY;
    });
    const sTokenBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.memo;
    });

    const trimmedStakingAPY = trim(stakingAPY * 100, 1);
    const trimmedSTokenBalance = trim(Number(sTokenBalance), 6);
    const trimmedMarketPrice = trim(marketPrice, 2);
    const isInfinity = stakingAPY * 100 >= 10000000000000; // >= 10T

    const [sTokenAmount, setSTokenAmount] = useState(trimmedSTokenBalance);
    const [rewardYield, setRewardYield] = useState(trimmedStakingAPY);
    const [priceAtPurchase, setPriceAtPurchase] = useState(trimmedMarketPrice);
    const [futureMarketPrice, setFutureMarketPrice] = useState(trimmedMarketPrice);
    // const [predictedMarketPrice, setPredictedMarketPrice] = useState(trimmedMarketPrice);
    const [days, setDays] = useState(30);

    const [rewardsEstimation, setRewardsEstimation] = useState("0");
    const [potentialReturn, setPotentialReturn] = useState("0");

    const calcInitialInvestment = () => {
        const sTokenAmt = Number(sTokenAmount) || 0;
        const price = parseFloat(priceAtPurchase) || 0;
        const amount = sTokenAmt * price;
        return trim(amount, 2);
    };

    const calcCurrentWealth = () => {
        const sTokenAmt = Number(sTokenAmount) || 0;
        const price = parseFloat(trimmedMarketPrice);
        const amount = sTokenAmt * price;
        return trim(amount, 2);
    };

    const [initialInvestment, setInitialInvestment] = useState(calcInitialInvestment());

    useEffect(() => {
        const newInitialInvestment = calcInitialInvestment();
        setInitialInvestment(newInitialInvestment);
    }, [sTokenAmount, priceAtPurchase]);

    const calcNewBalance = () => {
        let value = parseFloat(rewardYield) / 100;
        value = Math.pow(value - 1, 1 / (365 * 3)) - 1 || 0;
        let balance = Number(sTokenAmount);
        for (let i = 0; i < days * 3; i++) {
            balance += balance * value;
        }
        return balance;
    };

    useEffect(() => {
        const newBalance = calcNewBalance();
        setRewardsEstimation(trim(newBalance, 6));
        const newPotentialReturn = newBalance * (parseFloat(futureMarketPrice) || 0);
        setPotentialReturn(trim(newPotentialReturn, 2));
    }, [days, rewardYield, futureMarketPrice, sTokenAmount]);

    const calcRoi = (current: string, future: string) => {
        const currentAmount = Number(current);
        const futureAmount = Number(future);
        return trim(((futureAmount - currentAmount) / currentAmount) * 100, 2);
    };

    const calcFuturePriceMultipler = (current: string, future: string) => {
        const currentAmount = Number(current);
        const futureAmount = Number(future);
        return Math.floor(futureAmount / currentAmount);
    };

    const renderLocalCurreny = (amount: string) => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).format(Number(amount));
    };

    const renderLocalNumber = (amount: string) => {
        return new Intl.NumberFormat("en-US").format(Number(amount));
    };

    const currentRoi = renderLocalNumber(calcRoi(initialInvestment, calcCurrentWealth()));
    const estimatedRoi = renderLocalNumber(calcRoi(initialInvestment, potentialReturn));

    return (
        <div className="calculator-view">
            <Zoom in={true}>
                <div className="calculator-card">
                    <Grid className="calculator-card-grid" container direction="column" spacing={2}>
                        <Grid item>
                            <div className="calculator-card-header">
                                <p className="calculator-card-header-title">Calculator</p>
                                {/* <p className="calculator-card-header-subtitle">Estimate your returns</p> */}
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="calculator-card-metrics">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <div className="calculator-card-apy">
                                            <p className="calculator-card-metrics-title">{TOKEN_SYMBOL} Price</p>
                                            <p className="calculator-card-metrics-value">{isAppLoading ? <Skeleton width="100px" /> : `$${trimmedMarketPrice}`}</p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={4} lg={4}>
                                        <div className="calculator-card-tvl">
                                            <p className="calculator-card-metrics-title">Current APY</p>
                                            <p className="calculator-card-metrics-value">
                                                {isAppLoading ? (
                                                    <Skeleton width="100px" />
                                                ) : (
                                                    <>{`${isInfinity ? "∞ " : new Intl.NumberFormat("en-US").format(Number(trimmedStakingAPY))}%`}</>
                                                )}
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={4} lg={4}>
                                        <div className="calculator-card-index">
                                            <p className="calculator-card-metrics-title">Your {STOKEN_SYMBOL} Balance</p>
                                            <p className="calculator-card-metrics-value">{isAppLoading ? <Skeleton width="100px" /> : <>{trimmedSTokenBalance}</>}</p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>

                        <div className="calculator-card-area">
                            <div>
                                <div className="calculator-card-action-area">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <div className="calculator-card-action-area-inp-wrap">
                                                <p className="calculator-card-action-area-inp-wrap-title">{STOKEN_SYMBOL} Amount</p>
                                                <OutlinedInput
                                                    type="number"
                                                    placeholder="Amount"
                                                    className="calculator-card-action-input"
                                                    value={sTokenAmount}
                                                    onChange={e => setSTokenAmount(e.target.value)}
                                                    labelWidth={0}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <div onClick={() => setSTokenAmount(trimmedSTokenBalance)} className="stake-card-action-input-btn">
                                                                <p>Max</p>
                                                            </div>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className="calculator-card-action-area-inp-wrap">
                                                <p className="calculator-card-action-area-inp-wrap-title">APY (%)</p>
                                                <OutlinedInput
                                                    type="number"
                                                    placeholder="Amount"
                                                    className="calculator-card-action-input"
                                                    value={rewardYield}
                                                    onChange={e => setRewardYield(e.target.value)}
                                                    labelWidth={0}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <div onClick={() => setRewardYield(trimmedStakingAPY)} className="stake-card-action-input-btn">
                                                                <p>Current</p>
                                                            </div>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className="calculator-card-action-area-inp-wrap">
                                                <p className="calculator-card-action-area-inp-wrap-title">{TOKEN_SYMBOL} Price at Purchase ($)</p>
                                                <OutlinedInput
                                                    type="number"
                                                    placeholder="Amount"
                                                    className="calculator-card-action-input"
                                                    value={priceAtPurchase}
                                                    onChange={e => setPriceAtPurchase(e.target.value)}
                                                    labelWidth={0}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <div onClick={() => setPriceAtPurchase(trimmedMarketPrice)} className="stake-card-action-input-btn">
                                                                <p>Current</p>
                                                            </div>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className="calculator-card-action-area-inp-wrap">
                                                <p className="calculator-card-action-area-inp-wrap-title">Estimated {TOKEN_SYMBOL} Price ($)</p>
                                                <OutlinedInput
                                                    type="number"
                                                    placeholder="Amount"
                                                    className="calculator-card-action-input"
                                                    value={futureMarketPrice}
                                                    onChange={e => setFutureMarketPrice(e.target.value)}
                                                    labelWidth={0}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <div onClick={() => setFutureMarketPrice(trimmedMarketPrice)} className="stake-card-action-input-btn">
                                                                <p>Current</p>
                                                            </div>
                                                        </InputAdornment>
                                                    }
                                                />
                                                {/* <p className="calculator-card-action-area-inp-wrap-title">
                                                    *with market cap of OlympusDAO ({calcFuturePriceMultipler(marketPrice.toString(), predictedMarketPrice)}x)
                                                </p> */}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className="calculator-days-slider-wrap">
                                    <p className="calculator-days-slider-wrap-title">{`Duration: ${days} day${days > 1 ? "s" : ""}`}</p>
                                    <Slider className="calculator-days-slider" min={1} max={365} value={days} onChange={(e, newValue: any) => setDays(newValue)} />
                                </div>
                                <div className="calculator-user-data">
                                    <div className="data-row">
                                        <p className="data-row-name">Initial Investment</p>
                                        <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>{renderLocalCurreny(initialInvestment)}</>}</p>
                                    </div>
                                    <div className="data-row">
                                        <p className="data-row-name">Current Returns</p>
                                        <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>{renderLocalCurreny(calcCurrentWealth())}</>}</p>
                                    </div>
                                    <div className="data-row">
                                        <p className="data-row-name">Current ROI</p>
                                        <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>{currentRoi !== "NaN" ? currentRoi : 0}%</>}</p>
                                    </div>
                                    <div className="data-row">
                                        <p className="data-row-name">Estimated {TOKEN_SYMBOL} Rewards</p>
                                        <p className="data-row-value">
                                            {isAppLoading ? (
                                                <Skeleton width="80px" />
                                            ) : (
                                                <>
                                                    {rewardsEstimation} {TOKEN_SYMBOL}
                                                </>
                                            )}
                                        </p>
                                    </div>
                                    <div className="data-row">
                                        <p className="data-row-name">Estimated Returns</p>
                                        <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>{renderLocalCurreny(potentialReturn)}</>}</p>
                                    </div>
                                    <div className="data-row">
                                        <p className="data-row-name">Estimated ROI</p>
                                        <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>{estimatedRoi !== "NaN" ? estimatedRoi : 0}%</>}</p>
                                    </div>
                                    {/* <div className="data-row">
                                        <p className="data-row-name">Potential number of lambos</p>
                                        <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>{Math.floor(Number(potentialReturn) / 220000)}</>}</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </Grid>
                </div>
            </Zoom>
        </div>
    );
}

export default Calculator;
