import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from "@material-ui/core";
import { RewardTableData } from "./RewardRow";
import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { snapshot } from "../../helpers/redemption";
import { useWeb3Context } from "src/hooks";
import { NavLink } from "react-router-dom";
import { IPendingTxn, isPendingTxn } from "src/store/slices/pending-txns-slice";
import { Dispatch } from "redux";
import { useCallback } from "react";
import { changeApproval, claimReward } from "src/store/slices/treasury-redemption-slice.ts";
import { JsonRpcProvider } from "@ethersproject/providers";
import { TOKEN_SYMBOL } from "src/constants";

function ChooseBond() {
    const { provider, address, chainID, checkWrongNetwork } = useWeb3Context();
    const dispatch = useDispatch();

    const { token, dai } = snapshot;

    // Allocations in gwei, used for contract call
    const tokenAllocation = token.getAllocation(address);

    // Allocations for display
    const tokenAllocationDisplay = (Number(tokenAllocation) / 10 ** token.getDecimals()).toFixed(9);

    const eligible = Number(tokenAllocation) > 0;
    // const isActive = new Date().getTime() >= 1688443200000;
    const isActive = true;

    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);

    const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    const tokenBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.time;
    });

    const tokenAllowance = useSelector<IReduxState, number>(state => {
        return state.account.treasuryRedemption && state.account.treasuryRedemption.token;
    });

    const isClaimed = useSelector<IReduxState, boolean>(state => {
        return state.account.treasuryRedemption && state.account.treasuryRedemption.claimed;
    });

    const hasAllowance = useCallback(() => tokenAllowance > 0, [tokenAllowance]);
    console.log(tokenAllowance);

    const onSeekApproval = async () => {
        if (await checkWrongNetwork()) return;

        await dispatch(changeApproval({ address, provider, networkID: chainID }));
    };

    async function onClaim(provider: JsonRpcProvider, address: string, networkID: number, checkWrongNetwork: () => Promise<boolean>, dispatch: Dispatch<any>) {
        if (await checkWrongNetwork()) return;

        await dispatch(claimReward({ address, networkID, provider }));
    }

    return (
        <div className="choose-bond-view">
            <div className="choose-bond-view-card">
                <div className="choose-bond-view-card-header">
                    <p className="choose-bond-view-card-title">Redeem</p>
                    {/* <p className="choose-bond-view-card-subtitle">
                        <span>Redeem a share of the Treasury with {TOKEN_SYMBOL}</span>
                    </p> */}
                </div>

                {/* <Grid container item xs={12} spacing={2} className="choose-bond-view-card-metrics">
                    <Grid item xs={12} sm={12}>
                        <Box textAlign="center">
                            <p className="choose-bond-view-card-metrics-title">Your Eligible {TOKEN_SYMBOL}</p>
                            <p className="choose-bond-view-card-metrics-value">
                                {isAppLoading || address === "" ? (
                                    <Skeleton width="180px" />
                                ) : (
                                    <p className="bond-name-title">{eligible ? tokenAllocationDisplay : "Not Eligible"}&nbsp;</p>
                                )}
                            </p>
                        </Box>
                    </Grid>
                </Grid> */}

                <Grid container item>
                    <TableContainer className="choose-bond-view-card-table">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        <p className="choose-bond-view-card-table-title"></p>
                                    </TableCell>
                                    <TableCell align="center">
                                        <p className="choose-bond-view-card-table-title">Claimable</p>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[dai].map(reward => (
                                    <RewardTableData key={`list-${reward.id}`} reward={reward} account={address} isActive={isActive} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                <Grid container item>
                    <TableContainer className="choose-bond-view-card-table" style={{ paddingLeft: "50px", paddingRight: "50px" }}>
                        <br />
                        <p className="subtleText">
                            Your {TOKEN_SYMBOL} Balance: &nbsp;
                            <span className="text">{isAppLoading ? <Skeleton width="80px" /> : Number(tokenBalance).toFixed(9)}</span>
                        </p>
                        {eligible && (
                            <p className="subtleText">
                                Provide{" "}
                                <span className="text">
                                    {tokenAllocationDisplay} {TOKEN_SYMBOL}
                                </span>{" "}
                                to claim your share
                            </p>
                        )}

                        <Table>
                            <TableBody>
                                {isActive ? (
                                    <>
                                        <TableCell style={{ width: "50%" }}>
                                            <div className="bond-table-btn-container">
                                                <div className="bond-table-btn-subcontainer">
                                                    <Link component={NavLink} to="#">
                                                        <div
                                                            className={!hasAllowance() ? "bond-table-btn" : "bond-table-btn-disable"}
                                                            onClick={() => {
                                                                if (isPendingTxn(pendingTransactions, "approve_redemption")) return;
                                                                onSeekApproval();
                                                            }}
                                                        >
                                                            <p>{hasAllowance() ? "Approved" : "Approve"}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ width: "50%" }}>
                                            <div className="bond-table-btn-container">
                                                <div className="bond-table-btn-subcontainer">
                                                    <Link component={NavLink} to="#">
                                                        <div
                                                            className={hasAllowance() && eligible && !isClaimed ? "bond-table-btn" : "bond-table-btn-disable"}
                                                            onClick={() => {
                                                                if (isPendingTxn(pendingTransactions, "claim_reward_redemption")) return;
                                                                onClaim(provider, address, chainID, checkWrongNetwork, dispatch);
                                                            }}
                                                        >
                                                            <p>{eligible && !isClaimed && "Claim"}</p>
                                                            <p>{eligible && isClaimed && "Claimed"}</p>
                                                            <p>{!eligible && address !== "" && "Not Eligible"}</p>
                                                            <p>{address === "" && <Skeleton width="80px" />}</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </>
                                ) : (
                                    <TableCell style={{ width: "50%" }}>
                                        <div className="bond-table-btn-container">
                                            <div className="bond-table-btn-subcontainer">
                                                <Link component={NavLink} to="#">
                                                    <div className={"bond-table-btn-disable"}>
                                                        <p>Claiming is not active yet</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </TableCell>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </div>
        </div>
    );
}

export default ChooseBond;
