import { ethers } from "ethers";
import { LpReserveContract } from "../abi";
import { sexDai as tokenDai } from "../helpers/bond";
import { Networks } from "../constants/blockchain";

export async function getMarketPrice(networkID: Networks, provider: ethers.Signer | ethers.providers.Provider): Promise<number> {
    const lpTokenAddress = tokenDai.getAddressForReserve(networkID);
    const pairContract = new ethers.Contract(lpTokenAddress!, LpReserveContract, provider);
    const reserves = await pairContract.getReserves();
    const stablecoinReserves = ethers.utils.formatUnits(reserves[1], 18);
    const tokenReserves = ethers.utils.formatUnits(reserves[0], 9);
    // const stablecoinReserves = ethers.utils.formatUnits(reserves[0], 18); // Testnet
    // const tokenReserves = ethers.utils.formatUnits(reserves[1], 9); // Testnet
    const marketPrice = Number(stablecoinReserves) / Number(tokenReserves);
    return marketPrice;
}
