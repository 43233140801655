import { ContractInterface } from "ethers";
import { Bond, BondOpts } from "./bond";
import { BondType } from "./constants";
import { Networks } from "../../constants/blockchain";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { getBondCalculator } from "../bond-calculator";
import { getAddresses } from "../../constants/addresses";

// Keep all LP specific fields/logic within the LPBond class
export interface LPBondOpts extends BondOpts {
    readonly reserveContractAbi: ContractInterface;
    readonly lpUrl: string;
}

export class LPBond extends Bond {
    readonly isLP = true;
    readonly lpUrl: string;
    readonly reserveContractAbi: ContractInterface;
    readonly displayUnits: string;

    constructor(lpBondOpts: LPBondOpts) {
        super(BondType.LP, lpBondOpts);

        this.lpUrl = lpBondOpts.lpUrl;
        this.reserveContractAbi = lpBondOpts.reserveContractAbi;
        this.displayUnits = "LP";
    }

    async getTreasuryBalance(networkID: Networks, provider: StaticJsonRpcProvider) {
        const addresses = getAddresses(networkID);

        const token = this.getContractForReserve(networkID, provider);
        const tokenAddress = this.getAddressForReserve(networkID);
        const bondCalculator = getBondCalculator(networkID, provider);
        const tokenAmount = await token.balanceOf(addresses.TREASURY_ADDRESS);
        const [valuation, markdown] = await Promise.all([bondCalculator.valuation(tokenAddress, tokenAmount), bondCalculator.markdown(tokenAddress)]);
        const tokenUSD = (valuation / Math.pow(10, 9)) * (markdown / Math.pow(10, 18));

        return tokenUSD;
    }

    public getTokenAmount(networkID: Networks, provider: StaticJsonRpcProvider) {
        return this.getReserves(networkID, provider, true);
    }

    public getTimeAmount(networkID: Networks, provider: StaticJsonRpcProvider) {
        return this.getReserves(networkID, provider, false);
    }

    private async getReserves(networkID: Networks, provider: StaticJsonRpcProvider, isToken: boolean): Promise<number> {
        const addresses = getAddresses(networkID);

        const token = this.getContractForReserve(networkID, provider);

        let [[reserve0, reserve1], token1] = await Promise.all([token.getReserves(), token.token1()]);
        const isTime = token1.toLowerCase() === addresses.TIME_ADDRESS.toLowerCase();

        return isToken ? this.toTokenDecimal(false, isTime ? reserve0 : reserve1) : this.toTokenDecimal(true, isTime ? reserve1 : reserve0);
    }

    private toTokenDecimal(isTime: boolean, reserve: number) {
        return isTime ? reserve / Math.pow(10, 9) : reserve / Math.pow(10, 18);
    }
}

// These are special bonds that have different valuation methods
export interface CustomLPBondOpts extends LPBondOpts {}

export class CustomLPBond extends LPBond {
    constructor(customBondOpts: CustomLPBondOpts) {
        super(customBondOpts);

        this.getTreasuryBalance = async (networkID: Networks, provider: StaticJsonRpcProvider) => {
            const addresses = getAddresses(networkID);

            const token = this.getContractForReserve(networkID, provider);
            const balance = await token.balanceOf(addresses.TREASURY_ADDRESS);
            const tokenAmount = balance / Math.pow(10, 18);

            return tokenAmount * this.getTokenPrice();
        };

        this.getTokenAmount = async (networkID: Networks, provider: StaticJsonRpcProvider) => {
            const tokenAmount = await super.getTokenAmount(networkID, provider);
            const tokenPrice = this.getTokenPrice();

            return tokenAmount * tokenPrice;
        };
    }
}

// These are special bonds that have different valuation methods
export interface ExternalLPBond extends LPBondOpts {}

export class ExternalLPBond extends LPBond {
    constructor(customBondOpts: CustomLPBondOpts) {
        super(customBondOpts);

        this.getTreasuryBalance = async (networkID: Networks, provider: StaticJsonRpcProvider) => {
            const addresses = getAddresses(networkID);

            const token = this.getContractForReserve(networkID, provider);
            const bond = this.getContractForBond(networkID, provider);
            const [balance, assetPrice] = await Promise.all([token.balanceOf(addresses.TREASURY_ADDRESS), bond.assetPrice()]);
            const tokenAmount = balance / Math.pow(10, 18);
            const tokenPrice = assetPrice / Math.pow(10, 8);

            return tokenAmount * tokenPrice;
        };

        // TODO: This function is faulty, it should only count risk-free assets
        this.getTokenAmount = async (networkID: Networks, provider: StaticJsonRpcProvider) => {
            // Temp fix: Exclude all except png-avax
            if (this.name !== "png_avax_pgl") {
                return 0;
            }
            const bond = this.getContractForBond(networkID, provider);
            const [tokenAmount, assetPrice] = await Promise.all([super.getTokenAmount(networkID, provider), bond.assetPrice()]);
            const tokenPrice = assetPrice / Math.pow(10, 8);

            return tokenAmount * tokenPrice;
        };

        this.getTimeAmount = async (networkID: Networks, provider: StaticJsonRpcProvider) => {
            return 0;
        };
    }
}
