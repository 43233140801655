import { BondType, NetworkAddresses } from "./constants";
import { Networks } from "../../constants/blockchain";
import { ContractInterface, Contract } from "ethers";
import React from "react";
import { JsonRpcSigner, StaticJsonRpcProvider } from "@ethersproject/providers";
import { getTokenPrice } from "../token-price";

export interface BondOpts {
    readonly name: string; // Internal name used for references
    readonly displayName: string; // Displayname on UI
    // readonly bondIconSvg: string; //  SVG path for icons
    readonly bondIcon0Img: string;
    readonly bondIcon1Img?: string;
    readonly bondContractABI: ContractInterface; // ABI for contract
    readonly networkAddrs: NetworkAddresses; // Mapping of network --> Addresses
    readonly bondToken: string; // Unused, but native token to buy the bond.
    readonly subtitleUrl?: string; // For subtitle display
    readonly soldOut?: boolean; // For disabling at the UI
    readonly displayInfoName?: string; // Display project info at mint bond page
    readonly displayInfoDescription?: string; // Display project info at mint bond page
    readonly displayInfoLink?: string; // Display project info at mint bond page
}

export abstract class Bond {
    public readonly name: string;
    public readonly displayName: string;
    public readonly type: BondType;
    // public readonly bondIconSvg: string;
    public readonly bondIcon0Img: string;
    public readonly bondIcon1Img?: string;
    public readonly bondContractABI: ContractInterface; // Bond ABI
    public readonly networkAddrs: NetworkAddresses;
    public readonly bondToken: string;
    public readonly lpUrl?: string;
    public readonly subtitleUrl?: string;
    public readonly soldOut?: boolean;
    public readonly displayInfoName?: string;
    public readonly displayInfoDescription?: string;
    public readonly displayInfoLink?: string;

    // The following two fields will differ on how they are set depending on bond type
    public abstract isLP: boolean;
    protected abstract reserveContractAbi: ContractInterface; // Token ABI
    public abstract displayUnits: string;

    // Async method that returns a Promise
    public abstract getTreasuryBalance(networkID: Networks, provider: StaticJsonRpcProvider): Promise<number>;
    public abstract getTokenAmount(networkID: Networks, provider: StaticJsonRpcProvider): Promise<number>;
    public abstract getTimeAmount(networkID: Networks, provider: StaticJsonRpcProvider): Promise<number>;

    constructor(type: BondType, bondOpts: BondOpts) {
        this.name = bondOpts.name;
        this.displayName = bondOpts.displayName;
        this.type = type;
        // this.bondIconSvg = bondOpts.bondIconSvg;
        this.bondIcon0Img = bondOpts.bondIcon0Img;
        this.bondIcon1Img = bondOpts.bondIcon1Img;
        this.bondContractABI = bondOpts.bondContractABI;
        this.networkAddrs = bondOpts.networkAddrs;
        this.bondToken = bondOpts.bondToken;
        this.subtitleUrl = bondOpts.subtitleUrl;
        this.soldOut = bondOpts.soldOut;
        this.displayInfoName = bondOpts.displayInfoName;
        this.displayInfoDescription = bondOpts.displayInfoDescription;
        this.displayInfoLink = bondOpts.displayInfoLink;
    }

    public getAddressForBond(networkID: Networks) {
        return this.networkAddrs[networkID]?.bondAddress;
    }

    public getContractForBond(networkID: Networks, provider: StaticJsonRpcProvider | JsonRpcSigner) {
        const bondAddress = this.getAddressForBond(networkID);
        return new Contract(bondAddress!, this.bondContractABI, provider);
    }

    public getAddressForReserve(networkID: Networks) {
        return this.networkAddrs[networkID]?.reserveAddress;
    }

    public getContractForReserve(networkID: Networks, provider: StaticJsonRpcProvider | JsonRpcSigner) {
        const reserveAddress = this.getAddressForReserve(networkID);
        return new Contract(reserveAddress!, this.reserveContractAbi, provider);
    }

    protected getPriceCacheKey(): string {
        // DAI.e to DAI
        if (this.name === "dai") {
            return "DAI";
        }
        return this.displayName.split(" ")[0];
    }

    protected getTokenPrice(): number {
        return getTokenPrice(this.getPriceCacheKey().toUpperCase());
    }
}
