import React, { useEffect, useState } from "react";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { red } from "@material-ui/core/colors";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { useWeb3Context } from "../hooks";
import { loadTokenPrices } from "../helpers";
import Loading from "../components/Loader";

const theme = createTheme({
    palette: {
        primary: {
            main: red[500],
        },
    },
});

function Root() {
    // const isApp = (): boolean => {
    //     return window.location.host.includes("app");
    // };

    const { provider, chainID } = useWeb3Context();

    const [tokenPriceloading, setTokenPriceLoading] = useState(true);
    const [lpTokenPriceloading, setLpTokenPriceLoading] = useState(false);

    const loading = tokenPriceloading || lpTokenPriceloading;

    useEffect(() => {
        loadTokenPrices().then(() => setTokenPriceLoading(false));
        // loadLpTokenPrices(chainID, provider).then(() => setLpTokenPriceLoading(false));
        // loadTreasuryTvl().then(() => {});
    }, []);

    if (loading) return <Loading />;

    const app = () => (
        <HashRouter>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </HashRouter>
    );

    return app();
}

export default Root;
