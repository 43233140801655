export enum Networks {
    AVAX = 43114,
    AVAX_TESTNET = 43113,
    PULSECHAIN = 369,
    PULSECHAIN_TESTNET = 943,
}

export const DEFAULT_NETWORK = Networks.PULSECHAIN;

const toHex = (num: number) => `0x${Number(num).toString(16)}`;
export const RPC_URLS = {
    [Networks.AVAX]: "https://api.avax.network/ext/bc/C/rpc",
    [Networks.AVAX_TESTNET]: "https://api.avax-test.network/ext/bc/C/rpc",
    [Networks.PULSECHAIN]: "https://rpc.pulsechain.com",
    [Networks.PULSECHAIN_TESTNET]: "https://rpc.v4.testnet.pulsechain.com",
};

export const RPC_CONFIG = {
    [Networks.AVAX]: {
        chainId: toHex(43114),
        chainName: "Avalanche",
        rpcUrls: [RPC_URLS[Networks.AVAX]],
        blockExplorerUrls: ["https://cchain.explorer.avax.network"],
        nativeCurrency: {
            name: "AVAX",
            symbol: "AVAX",
            decimals: 18,
        },
    },
    [Networks.AVAX_TESTNET]: {
        chainId: toHex(43113),
        chainName: "Avalanche Fuji Testnet",
        rpcUrls: [RPC_URLS[Networks.AVAX_TESTNET]],
        blockExplorerUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
        nativeCurrency: {
            name: "AVAX",
            symbol: "AVAX",
            decimals: 18,
        },
    },
    [Networks.PULSECHAIN]: {
        chainId: toHex(369),
        chainName: "PulseChain",
        rpcUrls: [RPC_URLS[Networks.PULSECHAIN]],
        blockExplorerUrls: ["https://scan.pulsechain.com"],
        nativeCurrency: {
            name: "PLS",
            symbol: "PLS",
            decimals: 18,
        },
    },
    [Networks.PULSECHAIN_TESTNET]: {
        chainId: toHex(943),
        chainName: "PulseChain Testnet V4",
        rpcUrls: [RPC_URLS[Networks.PULSECHAIN_TESTNET]],
        blockExplorerUrls: ["https://scan.v4.testnet.pulsechain.com"],
        nativeCurrency: {
            name: "tPLS",
            symbol: "tPLS",
            decimals: 18,
        },
    },
};
