import { DEFAULT_NETWORK } from "../../constants/blockchain";
import { LPBond } from "./lp-bond";
import { StableBond } from "./stable-bond";

import TokenIcon from "../../assets/tokens/SEX.png";
import DaiIcon from "../../assets/tokens/DAI.png";
import UsdcIcon from "../../assets/tokens/USDC.png";
import UsdtIcon from "../../assets/tokens/USDT.png";

import { StableBondContract, LpBondContract, StableReserveContract, LpReserveContract } from "../../abi";
import { BONDS, LP_TOKENS, TOKENS } from "src/constants/addresses";
import { AMM_ADD_LIQUIDITY_URL } from "src/constants";

export const sexDai = new LPBond({
    name: BONDS.SEX_DAI.label,
    displayName: BONDS.SEX_DAI.symbol,
    bondToken: TOKENS.DAI.symbol,
    bondIcon0Img: TokenIcon,
    bondIcon1Img: DaiIcon,
    bondContractABI: LpBondContract,
    reserveContractAbi: LpReserveContract,
    networkAddrs: {
        [DEFAULT_NETWORK]: {
            bondAddress: BONDS.SEX_DAI.address, // SEX-DAI Bond
            reserveAddress: LP_TOKENS.SEX_DAI.address, // SEX-DAI LP
        },
    },
    lpUrl: AMM_ADD_LIQUIDITY_URL,
});

export const dai = new StableBond({
    name: BONDS.DAI.label,
    displayName: BONDS.DAI.symbol,
    bondToken: BONDS.DAI.symbol,
    bondIcon0Img: DaiIcon,
    bondContractABI: StableBondContract,
    reserveContractAbi: StableReserveContract,
    networkAddrs: {
        [DEFAULT_NETWORK]: {
            bondAddress: BONDS.DAI.address, // DAI Bond
            reserveAddress: TOKENS.DAI.address, // DAI
        },
    },
});

export const usdc = new StableBond({
    name: BONDS.USDC.label,
    displayName: BONDS.USDC.symbol,
    bondToken: BONDS.USDC.symbol,
    bondIcon0Img: UsdcIcon,
    bondContractABI: StableBondContract,
    reserveContractAbi: StableReserveContract,
    networkAddrs: {
        [DEFAULT_NETWORK]: {
            bondAddress: BONDS.USDC.address, // USDC Bond
            reserveAddress: TOKENS.USDC.address, // USDC
        },
    },
});

export const usdt = new StableBond({
    name: BONDS.USDT.label,
    displayName: BONDS.USDT.symbol,
    bondToken: BONDS.USDT.symbol,
    bondIcon0Img: UsdtIcon,
    bondContractABI: StableBondContract,
    reserveContractAbi: StableReserveContract,
    networkAddrs: {
        [DEFAULT_NETWORK]: {
            bondAddress: BONDS.USDT.address, // USDT Bond
            reserveAddress: TOKENS.USDT.address, // USDT
        },
    },
});

export default [sexDai, dai, usdc, usdt];
