import "./home.scss";
import { useSelector } from "react-redux";
import { Grid, Link } from "@material-ui/core";
import { IReduxState } from "../../store/slices/state.interface";
import { trim } from "../../helpers";
import { Skeleton } from "@material-ui/lab";
import { AMM_BUY_TOKEN_URL, AMM_NAME, NETWORK_NAME, PROJECT_NAME, TOKEN_SYMBOL } from "src/constants";
import { NavLink } from "react-router-dom";

function Home() {
    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const stakingAPY = useSelector<IReduxState, number>(state => {
        return state.app.stakingAPY;
    });

    const trimmedStakingAPY = trim(stakingAPY * 100, 0);
    const isInfinity = stakingAPY * 100 >= 10000000000000; // >= 10T

    return (
        <div className="home-view">
            <div className="home-card-clear">
                <div className="home-card-header">
                    <p className="home-card-header-title">
                        {isAppLoading ? <Skeleton width="100px" /> : <>{`${isInfinity ? "∞ " : new Intl.NumberFormat("en-US").format(Number(trimmedStakingAPY))}%`}</>}
                    </p>
                    <p className="home-card-header-subtitle">annual returns for mining {TOKEN_SYMBOL}</p>
                </div>

                <div className="home-card-area">
                    <div className="home-card-area-wrap">
                        <p className="home-card-area-title">Community owned</p>
                        <p className="home-card-area-title">Unpegged to fiat</p>
                        <p className="home-card-area-title">Censorship-resistant</p>
                        <p className="home-card-area-title">Backed by real assets</p>
                    </div>
                </div>

                <Grid className="home-card-grid" container direction="column" spacing={2}>
                    <div className="home-card-metrics">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <div className="home-card-apy">
                                    <p className="home-card-metrics-title">The Reserve Currency</p>
                                    <p className="home-card-metrics-title">designed to grow</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <div className="bond-table-btn-subcontainer">
                                    <Link component={NavLink} to={`/stake`}>
                                        <div className="bond-table-btn">
                                            <p>Start mining</p>
                                        </div>
                                    </Link>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                {/* <div className="social-container">
                    <Link href={PROJECT_TWITTER_URL} target="_blank">
                        <div className="social-subcontainer">
                            <div className="social-icon" style={{ backgroundColor: "#1da1f2" }}>
                                <SvgIcon htmlColor="white" component={Twitter} style={{ fontSize: "45px", position: "relative", top: "12px", left: "5px" }} />
                            </div>
                            <p>Twitter</p>
                        </div>
                    </Link>

                    <Link href={PROJECT_DEXSCREEN_URL} target="_blank">
                        <div className="social-subcontainer">
                            <div className="social-icon">
                                <SvgIcon htmlColor="white" component={Dexscreener} viewBox="0 0 252 300" style={{ fontSize: "60px", position: "relative" }} />
                            </div>
                            <p>{TOKEN_SYMBOL} Chart</p>
                        </div>
                    </Link>

                    <Link href={PROJECT_TELEGRAM_URL} target="_blank">
                        <div className="social-subcontainer">
                            <div className="social-icon" style={{ backgroundColor: "#2aabee" }}>
                                <Telegram htmlColor="white" viewBox="0 0 22 22" style={{ fontSize: "40px", position: "relative", top: "8px", left: "-4px" }} />
                            </div>
                            <p>Telegram</p>
                        </div>
                    </Link>
                </div> */}
            </div>

            <div className="home-card">
                <Grid className="home-card-grid" container direction="column" spacing={2}>
                    <Grid item>
                        <div className="home-card-header">
                            <p className="home-card-header-title">How do you start mining {TOKEN_SYMBOL}?</p>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="home-card-area">
                            <div className="home-card-area-wrap">
                                <p className="home-card-area-title">1. Connect your wallet to the {PROJECT_NAME} app</p>
                                <p className="home-card-area-title">
                                    2. Mint {TOKEN_SYMBOL} from the{" "}
                                    <Link component={NavLink} id="bond-nav" to="/mints">
                                        Mint
                                    </Link>{" "}
                                    tab or buy {TOKEN_SYMBOL} directly from{" "}
                                    <Link href={AMM_BUY_TOKEN_URL} target="_blank">
                                        {AMM_NAME}
                                    </Link>
                                </p>
                                <p className="home-card-area-title">3. Minting provides discounted {TOKEN_SYMBOL} and vests linearly over 5 days</p>
                                <p className="home-card-area-title">
                                    4. Once you have {TOKEN_SYMBOL}, stake it on the{" "}
                                    <Link component={NavLink} id="bond-nav" to="/stake">
                                        Stake
                                    </Link>{" "}
                                    tab and watch it grow
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <div className="home-card">
                <Grid className="home-card-grid" container direction="column" spacing={2}>
                    <Grid item>
                        <div className="home-card-header">
                            <p className="home-card-header-title">What is {PROJECT_NAME}?</p>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="home-card-area">
                            <div className="home-card-area-wrap">
                                <p className="home-card-area-title">
                                    {PROJECT_NAME} governs {TOKEN_SYMBOL} — a decentralized reserve currency designed to serve the ever growing Web3 financial ecosystem on{" "}
                                    {NETWORK_NAME}.
                                </p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item>
                        <div className="home-card-header">
                            <p className="home-card-header-title">What is the goal of {PROJECT_NAME}?</p>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="home-card-area">
                            <div className="home-card-area-wrap">
                                <p className="home-card-area-title">
                                    To build an uncensorable policy-controlled financial reserve currency using {TOKEN_SYMBOL}. A currency for the community, by the community.
                                    Backed by real assets and not just trust.
                                </p>
                                <p className="home-card-area-title">
                                    Unlike modern fiat currencies which are not backed by any assets, {TOKEN_SYMBOL} has a novel minting system that guarantees every mint is backed
                                    by real assets. The minting treasury is governed by {PROJECT_NAME} and acts as a resilient force, countering market fluctuations and ensuring{" "}
                                    {TOKEN_SYMBOL} remains a reliable store of value, even in the face of market uncertainty.
                                </p>
                                <p className="home-card-area-title">
                                    By deploying the technology on {NETWORK_NAME}, {TOKEN_SYMBOL} users are guaranteed 100% uptime, lower transaction fees, deep liquidity and a
                                    greener alternative to real world assets and proof-of-work assets such as Bitcoin.
                                </p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item>
                        <div className="home-card-header">
                            <p className="home-card-header-title">How do I use {TOKEN_SYMBOL}?</p>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="home-card-area">
                            <div className="home-card-area-wrap">
                                <p className="home-card-area-title">{TOKEN_SYMBOL} has multiple moving parts including staking and minting.</p>
                                <p className="home-card-area-title">
                                    To obtain {TOKEN_SYMBOL}, you can opt to mint it directly on the {PROJECT_NAME} app or buy it directly on the open market on {AMM_NAME}. Minting
                                    usually provides discounted {TOKEN_SYMBOL} using the market price as a reference and vests linearly over 5 days.
                                </p>
                                <p className="home-card-area-title">
                                    Once you have {TOKEN_SYMBOL}, stake it at the Stake tab to start mining additional {TOKEN_SYMBOL} — staking ensures your {TOKEN_SYMBOL} holdings
                                    keep up with the {PROJECT_NAME}-controlled inflation rate. It works by locking up your {TOKEN_SYMBOL} and giving you newly minted {TOKEN_SYMBOL}{" "}
                                    every time a rebase occurs.
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default Home;
