import { TokenLogo } from "../../components/BondLogo";
import { TableRow, TableCell } from "@material-ui/core";
import "./choosebond.scss";
import { Reward } from "src/helpers/redemption/reward";
import { Skeleton } from "@material-ui/lab";
import { trim } from "src/helpers";

interface IRewardProps {
    reward: Reward;
    account: string;
    isActive: boolean;
}

export function RewardTableData({ reward, account, isActive }: IRewardProps) {
    const isRewardLoading = account.length === 0;
    const allocation = Number(reward.getAllocation(account)) / 10 ** reward.getDecimals();
    const claimableAllocation = new Intl.NumberFormat("en-US").format(Number(trim(allocation, 2)));
    const symbol = reward.symbol;
    const eligible = Number(allocation) > 0;

    const renderRewards = () => (
        <>
            {isRewardLoading ? (
                <Skeleton width="50px" />
            ) : (
                <>
                    <p className="bond-name-title">{eligible ? claimableAllocation : "Not Eligible"}&nbsp;</p>
                    <p className="bond-name-subtitle">{eligible && symbol}</p>
                </>
            )}
        </>
    );

    return (
        <TableRow>
            <TableCell align="left" style={{ width: "30%" }}>
                <div className="bond-name">
                    <p className="bond-name-title">
                        <TokenLogo icon0Img={reward.icon0Img} />
                        {reward.symbol}
                    </p>
                </div>
            </TableCell>
            <TableCell align="right">
                <div className="bond-name-container">{isActive ? renderRewards() : <p className="bond-name-subtitle">TBC</p>}</div>
            </TableCell>
        </TableRow>
    );
}
