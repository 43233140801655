import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import { TimeTokenContract, MemoTokenContract, MimTokenContract, WrappedStakedTokenContract } from "../../abi";
import { setAll } from "../../helpers";

import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Bond } from "../../helpers/bond/bond";
import { Reward } from "src/helpers/reward/reward";
import { Networks } from "../../constants/blockchain";
import { RootState } from "../store";
import { IToken } from "../../helpers/tokens";

interface IGetBalances {
    address: string;
    networkID: Networks;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

interface IAccountBalances {
    balances: {
        memo: string;
        time: string;
        token: string;
        sToken: string;
        wsToken: string;
    };
}

export const getBalances = createAsyncThunk("account/getBalances", async ({ address, networkID, provider }: IGetBalances): Promise<IAccountBalances> => {
    const addresses = getAddresses(networkID);

    const tokenContract = new ethers.Contract(addresses.TOKEN_ADDRESS, TimeTokenContract, provider); // TOKEN
    const sTokenContract = new ethers.Contract(addresses.STOKEN_ADDRESS, MemoTokenContract, provider); // sTOKEN
    const wsTokenContract = new ethers.Contract(addresses.WSTOKEN_ADDRESS, WrappedStakedTokenContract, provider); // wsTOKEN
    const [tokenBalance, sTokenBalance, wsTokenBalance] = await Promise.all([
        tokenContract.balanceOf(address),
        sTokenContract.balanceOf(address),
        wsTokenContract.balanceOf(address),
    ]);

    return {
        balances: {
            time: ethers.utils.formatUnits(tokenBalance, "gwei"), // TOKEN
            memo: ethers.utils.formatUnits(sTokenBalance, "gwei"), // sTOKEN
            token: ethers.utils.formatUnits(tokenBalance, "gwei"), // TOKEN
            sToken: ethers.utils.formatUnits(sTokenBalance, "gwei"), // sTOKEN
            wsToken: ethers.utils.formatEther(wsTokenBalance), // wsTOKEN, 18 decimals
        },
    };
});

interface IAccountStaking {
    staking: {
        time: number;
        memo: number;
        token: number;
        sToken: number;
    };
}

export const getStaking = createAsyncThunk("account/getStaking", async ({ address, networkID, provider }: IGetBalances): Promise<IAccountStaking> => {
    const addresses = getAddresses(networkID);

    const tokenContract = new ethers.Contract(addresses.TOKEN_ADDRESS, TimeTokenContract, provider);
    const sTokenContract = new ethers.Contract(addresses.STOKEN_ADDRESS, MemoTokenContract, provider);
    const [token, sToken] = await Promise.all([tokenContract.allowance(address, addresses.STAKING_HELPER_ADDRESS), sTokenContract.allowance(address, addresses.STAKING_ADDRESS)]);

    return {
        staking: {
            time: Number(token),
            memo: Number(sToken),
            token: Number(token),
            sToken: Number(sToken),
        },
    };
});

interface ILoadAccountDetails {
    address: string;
    networkID: Networks;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

interface IUserAccountDetails {
    balances: {
        time: string;
        memo: string;
        token: string;
        sToken: string;
        wsToken: string;
    };
    staking: {
        time: number;
        memo: number;
        token: number;
        sToken: number;
    };
    wrapping: {
        sToken: number;
    };
    redeemAll: {
        token: number;
    };
    treasuryRedemption: {
        token: number;
        claimed: boolean;
    };
}

export const loadAccountDetails = createAsyncThunk("account/loadAccountDetails", async ({ networkID, provider, address }: ILoadAccountDetails): Promise<IUserAccountDetails> => {
    let timeBalance = 0;
    let sTokenBalance = 0;
    let wsTokenBalance = 0;

    let stakeAllowance = 0;
    let unstakeAllowance = 0;
    let wsTokenAllowance = 0;
    let redeemAllAllowance = 0;
    let treasuryRedemptionAllowance = 0;
    let treasuryRedemptionClaimed = false;

    const addresses = getAddresses(networkID);

    if (addresses.TOKEN_ADDRESS) {
        const tokenContract = new ethers.Contract(addresses.TOKEN_ADDRESS, TimeTokenContract, provider);
        // [timeBalance, stakeAllowance, redeemAllAllowance, treasuryRedemptionAllowance] = await Promise.all([
        //     tokenContract.balanceOf(address),
        //     tokenContract.allowance(address, addresses.STAKING_HELPER_ADDRESS),
        //     tokenContract.allowance(address, addresses.REDEEM_HELPER_ADDRESS),
        //     tokenContract.allowance(address, addresses.TREASURY_REDEMPTION_ADDRESS),
        // ]);
        [timeBalance, stakeAllowance, treasuryRedemptionAllowance] = await Promise.all([
            tokenContract.balanceOf(address),
            tokenContract.allowance(address, addresses.STAKING_HELPER_ADDRESS),
            tokenContract.allowance(address, addresses.TREASURY_REDEMPTION_ADDRESS),
        ]);
    }

    if (addresses.STOKEN_ADDRESS) {
        const sTokenContract = new ethers.Contract(addresses.STOKEN_ADDRESS, MemoTokenContract, provider);
        [sTokenBalance, unstakeAllowance] = await Promise.all([sTokenContract.balanceOf(address), sTokenContract.allowance(address, addresses.STAKING_ADDRESS)]);

        if (addresses.WSTOKEN_ADDRESS) {
            wsTokenAllowance = await sTokenContract.allowance(address, addresses.WSTOKEN_ADDRESS);
        }
    }

    if (addresses.WSTOKEN_ADDRESS) {
        const wsTokenContract = new ethers.Contract(addresses.WSTOKEN_ADDRESS, WrappedStakedTokenContract, provider);
        wsTokenBalance = await wsTokenContract.balanceOf(address);
    }

    // if (addresses.TREASURY_REDEMPTION_ADDRESS) {
    //     const treasuryRedemptionContract = new ethers.Contract(addresses.TREASURY_REDEMPTION_ADDRESS, MerkleDistributorMultiContract, provider);
    //     treasuryRedemptionClaimed = await treasuryRedemptionContract.claimed(address);
    // }

    return {
        balances: {
            time: ethers.utils.formatUnits(timeBalance, "gwei"),
            memo: ethers.utils.formatUnits(sTokenBalance, "gwei"),
            token: ethers.utils.formatUnits(timeBalance, "gwei"),
            sToken: ethers.utils.formatUnits(sTokenBalance, "gwei"),
            wsToken: ethers.utils.formatEther(wsTokenBalance),
        },
        staking: {
            time: Number(stakeAllowance),
            memo: Number(unstakeAllowance),
            token: Number(stakeAllowance),
            sToken: Number(unstakeAllowance),
        },
        wrapping: {
            sToken: Number(wsTokenAllowance),
        },
        redeemAll: {
            token: Number(redeemAllAllowance),
        },
        treasuryRedemption: {
            token: Number(treasuryRedemptionAllowance),
            claimed: treasuryRedemptionClaimed,
        },
    };
});

interface ICalcUserBondDetails {
    address: string;
    bond: Bond;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    networkID: Networks;
}

export interface IUserBondDetails {
    allowance: number;
    balance: number;
    avaxBalance: number;
    interestDue: number;
    bondMaturationBlock: number;
    pendingPayout: number; //Payout formatted in gwei.
}

export const calculateUserBondDetails = createAsyncThunk("account/calculateUserBondDetails", async ({ address, bond, networkID, provider }: ICalcUserBondDetails) => {
    if (!address) {
        return new Promise<any>(resevle => {
            resevle({
                bond: "",
                displayName: "",
                bondIconSvg: "",
                isLP: false,
                allowance: 0,
                balance: 0,
                interestDue: 0,
                bondMaturationBlock: 0,
                pendingPayout: "",
                avaxBalance: 0,
            });
        });
    }

    const bondContract = bond.getContractForBond(networkID, provider);
    const reserveContract = bond.getContractForReserve(networkID, provider);

    let interestDue, pendingPayout, bondMaturationBlock;

    const bondDetails = await bondContract.bondInfo(address);
    interestDue = bondDetails.payout / Math.pow(10, 9);
    bondMaturationBlock = Number(bondDetails.vesting) + Number(bondDetails.lastTime);
    pendingPayout = await bondContract.pendingPayoutFor(address);

    let allowance,
        balance = "0";

    [allowance, balance] = await Promise.all([reserveContract.allowance(address, bond.getAddressForBond(networkID)), reserveContract.balanceOf(address)]);
    const decimals = bond.name === "usdc" || bond.name === "usdt" ? 6 : 18;
    const balanceVal = ethers.utils.formatUnits(balance, decimals);

    const avaxBalance = await provider.getSigner().getBalance();
    const avaxVal = ethers.utils.formatEther(avaxBalance);

    const pendingPayoutVal = ethers.utils.formatUnits(pendingPayout, "gwei");

    return {
        bond: bond.name,
        displayName: bond.displayName,
        // bondIconSvg: bond.bondIconSvg,
        bondIcon0Img: bond.bondIcon0Img,
        bondIcon1Img: bond.bondIcon1Img,
        isLP: bond.isLP,
        allowance: Number(allowance),
        balance: Number(balanceVal),
        avaxBalance: Number(avaxVal),
        interestDue,
        bondMaturationBlock,
        pendingPayout: Number(pendingPayoutVal),
    };
});

interface ICalcUserTokenDetails {
    address: string;
    token: IToken;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    networkID: Networks;
}

export interface IUserTokenDetails {
    allowance: number;
    balance: number;
    isAvax?: boolean;
}

export const calculateUserTokenDetails = createAsyncThunk("account/calculateUserTokenDetails", async ({ address, token, networkID, provider }: ICalcUserTokenDetails) => {
    if (!address) {
        return new Promise<any>(resevle => {
            resevle({
                token: "",
                address: "",
                img: "",
                allowance: 0,
                balance: 0,
            });
        });
    }

    if (token.isAvax) {
        const avaxBalance = await provider.getSigner().getBalance();
        const avaxVal = ethers.utils.formatEther(avaxBalance);

        return {
            token: token.name,
            tokenIcon: token.img,
            balance: Number(avaxVal),
            isAvax: true,
        };
    }

    // const addresses = getAddresses(networkID);

    const tokenContract = new ethers.Contract(token.address, MimTokenContract, provider);

    let allowance = "0",
        balance = "0";

    // [allowance, balance] = await Promise.all([tokenContract.allowance(address, addresses.ZAPIN_ADDRESS), tokenContract.balanceOf(address)]);
    [balance] = await Promise.all([tokenContract.balanceOf(address)]);

    const balanceVal = Number(balance) / Math.pow(10, token.decimals);

    return {
        token: token.name,
        address: token.address,
        img: token.img,
        allowance: Number(allowance),
        balance: Number(balanceVal),
    };
});

interface ICalcUserRewardDetails {
    address: string;
    reward: Reward;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    networkID: Networks;
}

export interface IUserRewardDetails {
    balance: number;
    allocation: number;
    claimed: boolean;
}

export const calculateUserRewardDetails = createAsyncThunk("account/calculateUserRewardDetails", async ({ address, reward, networkID, provider }: ICalcUserRewardDetails) => {
    if (!address) {
        return new Promise<any>(resolve => {
            resolve({
                reward: "",
                displayInfoName: "",
                icon0Img: "",
                icon1Img: "",
                allocation: 0,
                claimed: false,
            });
        });
    }

    const distributorContract = reward.getContractForDistributor(networkID, provider);
    const claimed = await distributorContract.claimed(address);
    const allocationBalance = reward.getAllocation(address);
    const allocationVal = ethers.utils.formatEther(allocationBalance);

    return {
        reward: reward.id,
        displayInfoName: reward.displayInfoName,
        icon0Img: reward.icon0Img,
        icon1Img: reward.icon1Img,
        allocation: Number(allocationVal),
        claimed,
    };
});

export interface IAccountSlice {
    bonds: { [key: string]: IUserBondDetails };
    rewards: { [key: string]: IUserRewardDetails };
    balances: {
        memo: string;
        time: string;
        token: string;
        sToken: string;
        wsToken: string;
    };
    loading: boolean;
    staking: {
        time: number;
        memo: number;
        token: number;
        sToken: number;
    };
    wrapping: {
        sToken: number;
        wsToken: number;
    };
    redeemAll: {
        token: number;
    };
    treasuryRedemption: {
        token: number;
        claimed: boolean;
    };
    tokens: { [key: string]: IUserTokenDetails };
}

const initialState: IAccountSlice = {
    loading: true,
    bonds: {},
    rewards: {},
    balances: { memo: "", time: "", token: "", sToken: "", wsToken: "" },
    staking: { time: 0, memo: 0, token: 0, sToken: 0 },
    wrapping: { sToken: 0, wsToken: 0 },
    redeemAll: { token: 0 },
    treasuryRedemption: { token: 0, claimed: false },
    tokens: {},
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(getBalances.pending, state => {
                state.loading = true;
            })
            .addCase(getBalances.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getBalances.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(getStaking.pending, state => {
                state.loading = true;
            })
            .addCase(getStaking.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(getStaking.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(calculateUserBondDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
                if (!action.payload) return;
                const bond = action.payload.bond;
                state.bonds[bond] = action.payload;
                state.loading = false;
            })
            .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(calculateUserTokenDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(calculateUserTokenDetails.fulfilled, (state, action) => {
                if (!action.payload) return;
                const token = action.payload.token;
                state.tokens[token] = action.payload;
                state.loading = false;
            })
            .addCase(calculateUserTokenDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(calculateUserRewardDetails.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(calculateUserRewardDetails.fulfilled, (state, action) => {
                if (!action.payload) return;
                const reward = action.payload.reward;
                state.rewards[reward] = action.payload;
                state.loading = false;
            })
            .addCase(calculateUserRewardDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            });
    },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
