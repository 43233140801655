import { Box } from "@material-ui/core";
import { Bond } from "../helpers/bond/bond";

interface IBondLogoProps {
    bond: Bond;
}

function BondLogo({ bond }: IBondLogoProps) {
    return TokenLogo({
        icon0Img: bond.bondIcon0Img,
        icon1Img: bond.bondIcon1Img,
        isPair: bond.isLP,
    });
}

interface ITokenLogoProps {
    readonly icon0Img: string;
    readonly icon1Img?: string;
    readonly isPair?: boolean;
}

export function TokenLogo(props: ITokenLogoProps) {
    let style = { height: "32px", width: "32px", borderRadius: "50%" };

    return (
        <Box display="flex" alignItems="center" justifyContent="center" width={"64px"}>
            <img src={props.icon0Img} style={style} />
            {props.isPair && (
                <>
                    &nbsp;
                    <img src={props.icon1Img} style={style} />
                </>
            )}
        </Box>
    );
}

export default BondLogo;
