import { LP_TOKENS, TOKENS } from "./addresses";

export const PROJECT_NAME = "OlympulseX";
export const PROJECT_URL = "https://olympulsex.com";
export const PROJECT_DOCS_URL = "https://docs.olympulsex.com";
export const PROJECT_TWITTER_URL = "https://twitter.com/OlympulseX";
export const PROJECT_TELEGRAM_URL = "https://t.me/olympulsex";
export const PROJECT_DEXSCREEN_URL = `https://dexscreener.com/pulsechain/${LP_TOKENS.SEX_DAI.address}`;
export const PROJECT_COINGECKO_URL = "https://www.coingecko.com/en/coins/olympulsex";
export const PROJECT_COINMARKETCAP_URL = "https://coinmarketcap.com/currencies/olympulsex";
export const PROJECT_BRIDGE_URL = "https://bridge.pulsechain.com";

export const TOKEN_SYMBOL = "SEX";
export const STOKEN_SYMBOL = `s${TOKEN_SYMBOL}`;
export const WSTOKEN_SYMBOL = `ws${TOKEN_SYMBOL}`;

export const NETWORK_NAME = "PulseChain";

export const AMM_NAME = "PulseX";
export const AMM_BUY_TOKEN_URL = `https://app.pulsex.com/swap?inputCurrency=${TOKENS.DAI.address}&outputCurrency=${TOKENS.SEX.address}`;
export const AMM_ADD_LIQUIDITY_URL = `https://app.pulsex.com/add/${TOKENS.DAI.address}/${TOKENS.SEX.address}`;
