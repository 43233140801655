import { STOKEN_SYMBOL, TOKEN_SYMBOL, WSTOKEN_SYMBOL } from "src/constants";
import ProjectImg from "../assets/tokens/SEX.png";
import SProjectImg from "../assets/tokens/SEX.png";
import WsProjectImg from "../assets/tokens/SEX.png";

const TokenImages: { [key: string]: string } = {
    [TOKEN_SYMBOL.toLowerCase()]: ProjectImg,
    [STOKEN_SYMBOL.toLowerCase()]: SProjectImg,
    [WSTOKEN_SYMBOL.toLowerCase()]: WsProjectImg,
};

function toUrl(tokenPath: string): string {
    const host = window.location.origin;
    return `${host}/${tokenPath}`;
}

export function getTokenUrl(name: string) {
    const image = TokenImages[name.toLowerCase()];
    if (!image) {
        throw Error(`Token url doesn't support: ${name}`);
    }

    return toUrl(image);
}
