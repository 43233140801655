import { NetworkAddresses } from "./constants";
import { Networks } from "../../constants/blockchain";
import { ContractInterface, Contract, utils } from "ethers";
import { JsonRpcSigner, StaticJsonRpcProvider } from "@ethersproject/providers";

export interface DistributorSnapshot {
    readonly decimals: number;
    readonly airdrop: { [key: string]: string };
}

export interface distributorOpts {
    readonly id: string; // Internal name used for references
    readonly distributorContractABI: ContractInterface; // ABI for merkle distributor contract
    readonly networkAddrs: NetworkAddresses; // Mapping of network --> Addresses
}

export class Distributor {
    public readonly id: string;
    public readonly distributorContractABI: ContractInterface; // Merkle distributor ABI
    public readonly networkAddrs: NetworkAddresses;

    constructor(distributorOpts: distributorOpts) {
        this.id = distributorOpts.id;
        this.distributorContractABI = distributorOpts.distributorContractABI;
        this.networkAddrs = distributorOpts.networkAddrs;
    }

    public getAddressForDistributor(networkID: Networks) {
        return this.networkAddrs[networkID]?.distributorAddress;
    }

    public getContractForDistributor(networkID: Networks, provider: StaticJsonRpcProvider | JsonRpcSigner) {
        const distributorAddress = this.getAddressForDistributor(networkID);
        return new Contract(distributorAddress!, this.distributorContractABI, provider);
    }
}
