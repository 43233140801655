import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MonetizationOnOutlineIcon from "@material-ui/icons/MonetizationOnOutlined";
import HomeOutlineIcon from "@material-ui/icons/HomeRounded";
import RedeemIcon from "@material-ui/icons/StarOutline";

import Social from "./social";
import ProjectIcon from "../../../assets/icons/project-logo-white.png";
import { ReactComponent as DashboardIcon } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as StakeIcon } from "../../../assets/icons/stake.svg";
import CalculatorIcon from "../../../assets/icons/calculator.svg";
import { ReactComponent as BridgeIcon } from "../../../assets/icons/bridge.svg";
import { trim } from "../../../helpers";
import useBonds from "../../../hooks/bonds";
import { Link, SvgIcon } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./drawer-content.scss";
import classnames from "classnames";
import { AMM_ADD_LIQUIDITY_URL, AMM_BUY_TOKEN_URL, PROJECT_BRIDGE_URL, PROJECT_NAME, PROJECT_URL, TOKEN_SYMBOL } from "src/constants";

function NavContent() {
    const [isActive] = useState();
    const [buyOpen, setBuyOpen] = useState(false);
    const [addLiquidityOpen, setAddLiquidityOpen] = useState(false);
    const { bonds } = useBonds();

    // const showRedeemLink = new Date().getTime() >= 1688443200000;
    const showRedeemLink = true;

    const checkPage = useCallback((location: any, page: string): boolean => {
        const currentPath = location.pathname.replace("/", "");
        const isActivePath = (pagePath: string) => currentPath.indexOf(pagePath) >= 0 && page === pagePath;
        return ["home", "dashboard", "stake", "mints"].some(pagePath => isActivePath(pagePath));
    }, []);

    const handleBuyClick = () => {
        setBuyOpen(!buyOpen);
    };

    const handleAddLiquidityClick = () => {
        setAddLiquidityOpen(!addLiquidityOpen);
    };

    const visibleBonds = bonds.filter(bond => !bond.soldOut);

    return (
        <div className="dapp-sidebar">
            <div className="branding-header">
                <Link href={PROJECT_URL} target="_blank">
                    <img alt="logo" src={ProjectIcon} />
                    <p className="title">{PROJECT_NAME}</p>
                </Link>
            </div>

            <div className="dapp-menu-links">
                <div className="dapp-nav">
                    <Link
                        component={NavLink}
                        to="/home"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "home");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <HomeOutlineIcon style={{ fill: "white" }} />
                            <p>Home</p>
                        </div>
                    </Link>

                    <Link
                        component={NavLink}
                        to="/dashboard"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "dashboard");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <DashboardIcon style={{ fill: "white" }} />
                            <p>Dashboard</p>
                        </div>
                    </Link>

                    <Link
                        component={NavLink}
                        to="/stake"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "stake");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <StakeIcon style={{ fill: "white" }} />
                            <p>Stake</p>
                        </div>
                    </Link>

                    {/* <Link
                        component={NavLink}
                        to="/wrap"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "wrap");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <TransformOutlinedIcon htmlColor="white" />
                            <p>Wrap</p>
                        </div>
                    </Link> */}

                    <Link
                        component={NavLink}
                        id="bond-nav"
                        to="/mints"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "mints");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <MonetizationOnIcon htmlColor="white" />
                            <p>Mint</p>
                        </div>
                    </Link>

                    {visibleBonds.length > 0 && (
                        <div className="bond-discounts">
                            <div className="bond">
                                <p>
                                    &nbsp;
                                    <span className="bond-pair-roi">ROI</span>
                                </p>
                            </div>
                            {visibleBonds.map((bond, i) => (
                                <Link component={NavLink} to={`/mints/${bond.name}`} key={i} className={"bond"}>
                                    {!bond.bondDiscount ? (
                                        <Skeleton variant="text" width={"150px"} />
                                    ) : (
                                        <p>
                                            {bond.displayName}
                                            <span className="bond-pair-roi">{bond.bondDiscount && bond.soldOut ? "-" : `${trim(bond.bondDiscount * 100, 2)}%`}</span>
                                        </p>
                                    )}
                                </Link>
                            ))}
                        </div>
                    )}

                    {showRedeemLink && (
                        <Link
                            component={NavLink}
                            to="/redeem"
                            isActive={(match: any, location: any) => {
                                return checkPage(location, "redeem");
                            }}
                            className={classnames("button-dapp-menu", { active: isActive })}
                        >
                            <div className="dapp-menu-item">
                                <RedeemIcon htmlColor="white" />
                                <p>Redeem</p>
                            </div>
                        </Link>
                    )}

                    <Link href={AMM_BUY_TOKEN_URL} target="_blank" className={classnames("button-dapp-menu", { active: isActive })}>
                        <div className="dapp-menu-item-nested">
                            <MonetizationOnOutlineIcon htmlColor="white" />
                            <p style={{ flexGrow: 1 }}>
                                Buy ${TOKEN_SYMBOL} &nbsp;
                                <OpenInNewIcon htmlColor="#C4C4C6" style={{ height: "15px", position: "relative", top: "-5px" }} />
                            </p>
                        </div>
                    </Link>

                    <Link href={AMM_ADD_LIQUIDITY_URL} target="_blank" className={classnames("button-dapp-menu", { active: isActive })}>
                        <div className="dapp-menu-item-nested">
                            <MonetizationOnOutlineIcon htmlColor="white" />
                            <p style={{ flexGrow: 1 }}>
                                Add {TOKEN_SYMBOL}-DAI LP &nbsp;
                                <OpenInNewIcon htmlColor="#C4C4C6" style={{ height: "15px", position: "relative", top: "-5px" }} />
                            </p>
                        </div>
                    </Link>

                    <Link
                        component={NavLink}
                        to="/calculator"
                        isActive={(match: any, location: any) => {
                            return checkPage(location, "calculator");
                        }}
                        className={classnames("button-dapp-menu", { active: isActive })}
                    >
                        <div className="dapp-menu-item">
                            <img alt="calculator" src={CalculatorIcon} />
                            <p>Calculator</p>
                        </div>
                    </Link>

                    <Link href={PROJECT_BRIDGE_URL} target="_blank" className={classnames("button-dapp-menu", { active: isActive })}>
                        <div className="dapp-menu-item-nested">
                            <SvgIcon htmlColor="white" component={BridgeIcon} />
                            <p style={{ flexGrow: 1 }}>
                                Bridge
                                <OpenInNewIcon htmlColor="#C4C4C6" style={{ height: "15px", position: "relative", top: "-5px" }} />
                            </p>
                        </div>
                    </Link>
                    <br />
                </div>
            </div>
            <div className="dapp-menu-doc-link"></div>

            <Social />

            <div className="quote"></div>
        </div>
    );
}

export default NavContent;
