import { Networks } from "../../constants/blockchain";

export enum BondType {
    StableAsset,
    LP,
}

export interface BondAddresses {
    reserveAddress: string;
    bondAddress: string;
    allocatorAddress?: string;
}

export enum BondAction {
    Mint,
    Redeem,
}

export type NetworkAddresses = {
    [key in Networks]?: BondAddresses;
};
