import { fetchAccountSuccess } from "./account-slice";
import { fetchPendingTxns, clearPendingTxn } from "./pending-txns-slice";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import distributor, { snapshot } from "../../helpers/redemption";
import { Networks } from "../../constants/blockchain";
import { RootState } from "../store";
import { error, warning, success, info } from "./messages-slice";
import { messages } from "../../constants/messages";
import { getGasPrice } from "../../helpers/get-gas-price";
import { metamaskErrorWrap } from "../../helpers/metamask-error-wrap";
import { sleep } from "../../helpers";
import { getAddresses } from "src/constants";
import { TimeTokenContract } from "src/abi";

export interface IChangeApproval {
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    networkID: Networks;
    address: string;
}

export const changeApproval = createAsyncThunk("treasuryRedemption/changeApproval", async ({ provider, address, networkID }: IChangeApproval, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }

    const addresses = getAddresses(networkID);
    const { TOKEN_ADDRESS, TREASURY_REDEMPTION_ADDRESS } = addresses;
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(TOKEN_ADDRESS, TimeTokenContract, signer);

    let approveTx;
    try {
        const gasPrice = await getGasPrice(provider);

        approveTx = await tokenContract.approve(TREASURY_REDEMPTION_ADDRESS, ethers.constants.MaxUint256, { gasPrice });

        const text = "Approve Redemption";
        const pendingTxnType = "approve_redemption";

        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
        return metamaskErrorWrap(err, dispatch);
    } finally {
        if (approveTx) {
            dispatch(clearPendingTxn(approveTx.hash));
        }
    }

    await sleep(2);

    const tokenAllowance = await tokenContract.allowance(address, TREASURY_REDEMPTION_ADDRESS);

    return dispatch(
        fetchAccountSuccess({
            treasuryRedemption: {
                token: Number(tokenAllowance),
            },
        }),
    );
});

interface IClaimReward {
    address: string;
    networkID: Networks;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

export const claimReward = createAsyncThunk("treasuryRedemption/claim", async ({ address, networkID, provider }: IClaimReward, { dispatch }) => {
    if (!provider) {
        dispatch(warning({ text: messages.please_connect_wallet }));
        return;
    }

    const { token, dai } = snapshot;
    const signer = provider.getSigner();
    const distributorContract = distributor.getContractForDistributor(networkID, signer);
    const formattedAddress: string = ethers.utils.getAddress(address);

    const tokenAllocation = token.getAllocation(formattedAddress);
    const daiAllocation = dai.getAllocation(formattedAddress);
    const tokenProof = token.generateMerkleProof(formattedAddress, tokenAllocation);
    const daiProof = dai.generateMerkleProof(formattedAddress, daiAllocation);

    let claimTx;
    try {
        const gasPrice = await getGasPrice(provider);

        claimTx = await distributorContract.claim(tokenAllocation, daiAllocation, tokenProof, daiProof, { gasPrice });
        const pendingTxnType = "claim_reward_redemption";
        dispatch(
            fetchPendingTxns({
                txnHash: claimTx.hash,
                text: "Claiming Redemption",
                type: pendingTxnType,
            }),
        );
        await claimTx.wait();
        dispatch(success({ text: messages.tx_successfully_send }));
        await sleep(0.01);
        dispatch(info({ text: messages.your_balance_update_soon }));
        return;
    } catch (err: any) {
        metamaskErrorWrap(err, dispatch);
    } finally {
        if (claimTx) {
            dispatch(clearPendingTxn(claimTx.hash));
        }
    }
});

export interface ITreasuryRedemptionSlice {
    loading: boolean;
    [key: string]: any;
}

const initialState: ITreasuryRedemptionSlice = {
    loading: true,
};

const treasuryRedemptionSlice = createSlice({
    name: "treasuryRedemption",
    initialState,
    reducers: {
        fetchRewardSuccess(state, action) {
            state[action.payload.reward] = action.payload;
        },
    },
});

export default treasuryRedemptionSlice.reducer;

export const { fetchRewardSuccess } = treasuryRedemptionSlice.actions;

const baseInfo = (state: RootState) => state.reward;

export const getTreasuryRedemptionState = createSelector(baseInfo, reward => reward);
