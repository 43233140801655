import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Paper, Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { BondTableData, BondDataCard, BondTableRedeemAll, BondDataCardRedeemAll } from "./BondRow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { trim } from "../../helpers";
import useBonds from "../../hooks/bonds";
import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import classnames from "classnames";
import { AMM_NAME, TOKEN_SYMBOL } from "src/constants";

function ChooseBond() {
    const { bonds } = useBonds();
    const isSmallScreen = useMediaQuery("(max-width: 733px)"); // change to breakpoint query

    const [activeBonds, setActiveBonds] = useState(bonds);
    const [view, setView] = useState(0); // 0: All, 1: active

    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const marketPrice = useSelector<IReduxState, number>(state => {
        return state.app.marketPrice;
    });

    const treasuryBalance = useSelector<IReduxState, number>(state => {
        return state.app.treasuryBalance;
    });

    useEffect(() => {
        setActiveBonds(bonds);
    }, [bonds]);

    const changeView = (newView: number) => () => {
        // 0: All, 1: active
        if (newView === 1) {
            setActiveBonds(bonds.filter(bond => bond.pendingPayout !== 0));
        } else {
            setActiveBonds(bonds);
        }
        setView(newView);
    };

    return (
        <div className="choose-bond-view">
            <div className="choose-bond-view-card">
                <div className="choose-bond-view-card-header">
                    <p className="choose-bond-view-card-title">Mint {TOKEN_SYMBOL}</p>
                </div>

                <Grid container item xs={12} spacing={2} className="choose-bond-view-card-metrics">
                    <Grid item xs={12} sm={6}>
                        <Box textAlign="center">
                            <p className="choose-bond-view-card-metrics-title">{TOKEN_SYMBOL} Price</p>
                            <p className="choose-bond-view-card-metrics-value">{isAppLoading ? <Skeleton width="100px" /> : `$${trim(marketPrice, 2)}`}</p>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box textAlign="center">
                            <p className="choose-bond-view-card-metrics-title">Treasury Balance</p>
                            <p className="choose-bond-view-card-metrics-value">
                                {isAppLoading ? (
                                    <Skeleton width="180px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(treasuryBalance)
                                )}
                            </p>
                        </Box>
                    </Grid>
                </Grid>

                {/* <div className="choose-bond-view-card-action-area">
                    <div className="choose-bond-view-card-action-stage-btns-wrap">
                        <div onClick={changeView(0)} className={classnames("choose-bond-view-card-action-stage-btn", { active: !view })}>
                            <p>All</p>
                        </div>
                        <div onClick={changeView(1)} className={classnames("choose-bond-view-card-action-stage-btn", { active: view })}>
                            <p>Active</p>
                        </div>
                    </div>
                </div> */}

                {!isSmallScreen && (
                    <>
                        <Grid container item>
                            <TableContainer className="choose-bond-view-card-table">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                <p className="choose-bond-view-card-table-title"></p>
                                            </TableCell>
                                            <TableCell align="center">
                                                <p className="choose-bond-view-card-table-title">Price</p>
                                            </TableCell>
                                            <TableCell align="center">
                                                <p className="choose-bond-view-card-table-title">ROI</p>
                                            </TableCell>
                                            <TableCell align="right">
                                                <p className="choose-bond-view-card-table-title">TVL</p>
                                            </TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* <BondTableRedeemAll /> */}
                                        {activeBonds.map(bond => (
                                            <BondTableData key={`list-${bond.name}`} bond={bond} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <div className="choose-bond-view-card-clear">
                            <div className="choose-bond-view-card-header">
                                <p>
                                    Minting is a financial primitive designed to give {TOKEN_SYMBOL} its backing. Mint {TOKEN_SYMBOL} with the assets listed — these assets give{" "}
                                    {TOKEN_SYMBOL} its backing.
                                </p>
                                <p>
                                    <b>Positive ROI</b> indicates that minting {TOKEN_SYMBOL} is cheaper than its market price.
                                </p>
                                <p>
                                    <b>Negative ROI</b> indicates that minting {TOKEN_SYMBOL} is more expensive than its market price. Buying it on {AMM_NAME} will be cheaper.
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>

            {isSmallScreen && (
                <>
                    <div className="choose-bond-view-card-container">
                        {/* <BondDataCardRedeemAll />
                    <br /> */}
                        <Grid container item spacing={2}>
                            {activeBonds.map(bond => (
                                <Grid item xs={12} key={`list-${bond.name}-mobile`}>
                                    <BondDataCard key={bond.name} bond={bond} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    <div className="choose-bond-view-card-clear">
                        <div className="choose-bond-view-card-header">
                            <p>
                                Minting is a financial primitive designed to give {TOKEN_SYMBOL} its backing. Mint {TOKEN_SYMBOL} with the assets listed — these assets give{" "}
                                {TOKEN_SYMBOL} its backing.
                            </p>
                            <p>
                                <b>Positive ROI</b> indicates that minting {TOKEN_SYMBOL} is cheaper than its market price.
                            </p>
                            <p>
                                <b>Negative ROI</b> indicates that minting {TOKEN_SYMBOL} is more expensive than its market price. Buying it on {AMM_NAME} will be cheaper.
                            </p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default ChooseBond;
