import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { trim } from "../../helpers";
import "./dashboard.scss";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { IAppSlice } from "../../store/slices/app-slice";
import useBonds from "../../hooks/bonds";
// import TreasuryTvlChart from "./TreasuryTvlChart";
import { STOKEN_SYMBOL, TOKEN_SYMBOL, WSTOKEN_SYMBOL } from "src/constants";

interface Asset {
    name: string;
    displayName: string;
    share: number;
}

function Dashboard() {
    const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
    const app = useSelector<IReduxState, IAppSlice>(state => state.app);
    const { bonds } = useBonds();

    const tokenBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.token;
    });
    const sTokenBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.sToken;
    });
    const wsTokenBalance = useSelector<IReduxState, string>(state => {
        return state.account.balances && state.account.balances.wsToken;
    });

    const bondAssets = bonds.map(bond => {
        return {
            name: bond.name,
            displayName: bond.displayName,
            balance: bond.purchased,
        };
    });
    const treasuryAssets = app.treasuryAssets.filter(asset => asset.balance > 0);
    const combinedAssets = bondAssets.concat(treasuryAssets);

    let sharesLeft = 100;
    const assets: Asset[] = combinedAssets
        .map(bond => {
            const share = Number(((bond.balance / app.treasuryBalance) * 100).toFixed(2));
            sharesLeft -= share;
            return {
                name: bond.name,
                displayName: bond.displayName,
                share,
            };
        })
        .sort((a, b) => b.share - a.share);
    assets[0].share += sharesLeft;

    const trimmedStakingAPY = trim(app.stakingAPY * 100, 1);
    const trimmedTotalSupply = trim(app.totalSupply, 0);
    const isInfinity = app.stakingAPY * 100 >= 10000000000000; // >= 10T

    const tokenBalanceUsd = Number(tokenBalance) * app.marketPrice;
    const sTokenBalanceUsd = Number(sTokenBalance) * app.marketPrice;
    const wsTokenBalanceUsd = Number(wsTokenBalance) * Number(app.currentIndex) * app.marketPrice;
    const totalBalanceUsd = tokenBalanceUsd + sTokenBalanceUsd + wsTokenBalanceUsd;

    return (
        <div className="dashboard-view">
            <div className="dashboard-infos-wrap" style={{ width: "100%" }}>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="dashboard-section">
                            <p className="section-title">Your Portfolio</p>
                            <p className="section-subtitle">
                                {isAppLoading ? (
                                    <Skeleton width="100px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(totalBalanceUsd)
                                )}
                            </p>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">{TOKEN_SYMBOL}</p>
                            <p className="card-value">{isAppLoading ? <Skeleton width="250px" /> : `${new Intl.NumberFormat("en-US").format(Number(tokenBalance))}`}</p>
                            {/* <p className="card-subvalue">
                                {isAppLoading ? (
                                    <Skeleton width="100px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(tokenBalanceUsd)
                                )}
                            </p> */}
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">{STOKEN_SYMBOL}</p>
                            <p className="card-value">{isAppLoading ? <Skeleton width="250px" /> : `${new Intl.NumberFormat("en-US").format(Number(sTokenBalance))}`}</p>
                            {/* <p className="card-subvalue">
                                {isAppLoading ? (
                                    <Skeleton width="100px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(sTokenBalanceUsd)
                                )}
                            </p> */}
                        </div>
                    </Grid>
                    {/* <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">{WSTOKEN_SYMBOL}</p>
                            <p className="card-value">{isAppLoading ? <Skeleton width="250px" /> : `${new Intl.NumberFormat("en-US").format(Number(wsTokenBalance))}`}</p>
                            <p className="card-subvalue">
                                {isAppLoading ? (
                                    <Skeleton width="100px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(wsTokenBalanceUsd)
                                )}
                            </p>
                        </div>
                    </Grid> */}
                </Grid>
            </div>

            <br />

            <div className="dashboard-infos-wrap">
                <Grid container spacing={4}>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">Market Cap</p>
                            <p className="card-value">
                                {isAppLoading ? (
                                    <Skeleton width="160px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(app.marketCap)
                                )}
                            </p>
                        </div>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">Total Value Locked</p>
                            <p className="card-value">
                                {isAppLoading ? (
                                    <Skeleton width="250px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(app.stakingTVL)
                                )}
                            </p>
                        </div>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">Treasury Balance</p>
                            <p className="card-value">
                                {isAppLoading ? (
                                    <Skeleton width="250px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(app.treasuryBalance)
                                )}
                            </p>
                        </div>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">{TOKEN_SYMBOL} Price</p>
                            <p className="card-value">
                                {isAppLoading ? (
                                    <Skeleton width="100px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2,
                                    }).format(app.marketPrice)
                                )}
                            </p>
                        </div>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">{TOKEN_SYMBOL} Staked</p>
                            <p className="card-value">{isAppLoading ? <Skeleton width="100px" /> : `${trim(Number(app.stakedSupply), 2)}%`}</p>
                        </div>
                    </Grid>

                    {/* <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">Supply (Staked/Total)</p>
                            <p className="card-value">
                                {isAppLoading ? (
                                    <Skeleton width="250px" />
                                ) : (
                                    `${new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(app.circSupply)}
                                    /
                                    ${new Intl.NumberFormat("en-US", {
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(app.totalSupply)}`
                                )}
                            </p>
                        </div>
                    </Grid> */}

                    {/* <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">Backing per {TOKEN_SYMBOL}</p>
                            <p className="card-value">
                                {isAppLoading ? (
                                    <Skeleton width="250px" />
                                ) : (
                                    new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                    }).format(app.rfv)
                                )}
                            </p>
                        </div>
                    </Grid> */}

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">{TOKEN_SYMBOL} Supply</p>
                            <p className="card-value">{isAppLoading ? <Skeleton width="250px" /> : `${new Intl.NumberFormat("en-US").format(Number(trimmedTotalSupply))}`}</p>
                        </div>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">APY</p>
                            <p className="card-value">
                                {isAppLoading ? <Skeleton width="250px" /> : `${isInfinity ? "∞ " : new Intl.NumberFormat("en-US").format(Number(trimmedStakingAPY))}%`}
                            </p>
                        </div>
                    </Grid>

                    {/* <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">Runway</p>
                            <p className="card-value">{isAppLoading ? <Skeleton width="250px" /> : `${trim(Number(app.runway), 1)} Days`}</p>
                        </div>
                    </Grid> */}

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className="dashboard-card">
                            <p className="card-title">Current Index</p>
                            <p className="card-value">{isAppLoading ? <Skeleton width="250px" /> : `${trim(Number(app.currentIndex), 2)} ${STOKEN_SYMBOL}`}</p>
                        </div>
                    </Grid>
                </Grid>
            </div>

            <br />

            {/* <div className="dashboard-infos-wrap" style={{ width: "100%" }}>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="dashboard-section">
                            <p className="section-title">Treasury Balance</p>
                        </div>
                    </Grid>
                    <TreasuryTvlChart />
                </Grid>
            </div> */}

            {/* <div className="dashboard-infos-wrap" style={{ width: "100%" }}>
                <Grid container spacing={4}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className="dashboard-section">
                            <p className="section-title">Asset Exposure</p>
                        </div>
                    </Grid>
                    {assets.map(asset => (
                        <Grid item lg={4} md={4} sm={4} xs={12} key={`exposure-${asset.name}`}>
                            <div className="dashboard-card">
                                <p className="card-title">{asset.displayName}</p>
                                <p className="card-value">{isAppLoading ? <Skeleton width="250px" /> : `${trim(asset.share, 2)}%`}</p>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div> */}
        </div>
    );
}

export default Dashboard;
