import { useEffect, useState, useMemo, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import jazzicon from "@metamask/jazzicon";
import { useWeb3Context, useAddress } from "../../../hooks";
import { DEFAULT_NETWORK, RPC_CONFIG } from "../../../constants";
import { IReduxState } from "../../../store/slices/state.interface";
import { IPendingTxn } from "../../../store/slices/pending-txns-slice";
import "./connect-menu.scss";
import { CircularProgress, Fade, Link, Popper } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { shorten } from "src/helpers";

function ConnectMenu() {
    const { connect, disconnect, connected, web3, providerChainID, checkWrongNetwork } = useWeb3Context();
    const dispatch = useDispatch();
    const address = useAddress();
    const [isConnected, setConnected] = useState(connected);
    const [anchorEl, setAnchorEl] = useState(null);

    let pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
        return state.pendingTransactions;
    });

    let buttonText = "Connect Wallet";
    let clickFunc: any = connect;
    let buttonStyle = {};

    if (isConnected) {
        buttonText = shorten(address);
        // clickFunc = disconnect;
    }

    if (pendingTransactions && pendingTransactions.length > 0) {
        buttonText = `${pendingTransactions.length} Pending `;
        clickFunc = () => {};
    }

    if (isConnected && providerChainID !== DEFAULT_NETWORK) {
        buttonText = "Wrong network";
        buttonStyle = { backgroundColor: "rgb(255, 67, 67)" };
        clickFunc = () => {
            checkWrongNetwork();
        };
    }

    useEffect(() => {
        setConnected(connected);
    }, [web3, connected]);

    const handleClick = (event: any) => {
        if (isConnected) {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
    };

    const icon = useMemo(() => address && jazzicon(18, parseInt(address.slice(2, 10), 16)), [address]);
    const iconRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        const current = iconRef.current;
        if (icon) {
            current?.appendChild(icon);
            return () => {
                try {
                    current?.removeChild(icon);
                } catch (e) {
                    console.error("Avatar icon not found");
                }
            };
        }
        return;
    }, [icon, iconRef]);

    const open = Boolean(anchorEl);

    return (
        <div className="menu-root" onMouseEnter={e => handleClick(e)} onMouseLeave={e => handleClick(e)}>
            <div className="connect-button" style={buttonStyle} onClick={clickFunc}>
                <p>{buttonText}</p>
                {pendingTransactions.length > 0 && (
                    <div className="connect-button-progress">
                        <CircularProgress size={15} color="inherit" />
                    </div>
                )}
                {/* {isConnected && providerChainID === DEFAULD_NETWORK && <span ref={iconRef}>&nbsp;</span>} */}
            </div>

            <Popper className="menu-popper" open={open} anchorEl={anchorEl} placement="bottom-end" transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={200}>
                        <div className="tooltip">
                            <Link className="tooltip-item" href={`${RPC_CONFIG[DEFAULT_NETWORK]}/address/${address}`} target="_blank">
                                <p>
                                    View on Explorer &nbsp;
                                    <OpenInNewIcon htmlColor="#C4C4C6" style={{ fontSize: "14px" }} />
                                </p>
                            </Link>

                            <div className="connect-button" onClick={disconnect}>
                                <p>Disconnect</p>
                            </div>
                        </div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}

export default ConnectMenu;
