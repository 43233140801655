import { LinearProgress } from "@material-ui/core";
import ProjectIcon from "../../assets/icons/project-logo-white.png";
import "./loader.scss";

function Loader() {
    return (
        <div className="loader-wrap">
            <img className="project-splash" alt="project-logo" src={ProjectIcon} />
            <br />
            <div className="loader-bar">
                <LinearProgress />
            </div>
        </div>
    );
}

export default Loader;
