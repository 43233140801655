import { SvgIcon, Link } from "@material-ui/core";
// import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
// import { ReactComponent as GitHub } from "../../../assets/icons/github.svg";
import { ReactComponent as Twitter } from "../../../assets/icons/twitter.svg";
// import { ReactComponent as Telegram } from "../../../assets/icons/telegram.svg";
// import { ReactComponent as Dexscreener } from "../../../assets/icons/dexscreener.svg";
import { ReactComponent as Coingecko } from "../../../assets/icons/coingecko.svg";
import { ReactComponent as Coinmarketcap } from "../../../assets/icons/coinmarketcap.svg";
import Telegram from "@material-ui/icons/Telegram";
import { PROJECT_TELEGRAM_URL, PROJECT_TWITTER_URL, PROJECT_COINGECKO_URL, PROJECT_COINMARKETCAP_URL } from "src/constants";
// import { ReactComponent as Discord } from "../../../assets/icons/discord.svg";
// import { ReactComponent as Medium } from "../../../assets/icons/medium.svg";

export default function Social() {
    return (
        <div className="social-row">
            {/* <Link href={PROJECT_DOCS_URL} target="_blank">
                <DescriptionOutlinedIcon htmlColor="white" />
            </Link> */}

            {/* <Link href="https://github.com" target="_blank">
                <SvgIcon htmlColor="white" component={GitHub} />
            </Link> */}

            <Link href={PROJECT_COINGECKO_URL} target="_blank">
                <SvgIcon htmlColor="white" component={Coingecko} viewBox="0 0 1080 1080" />
            </Link>

            <Link href={PROJECT_COINMARKETCAP_URL} target="_blank">
                <SvgIcon htmlColor="white" component={Coinmarketcap} viewBox="0 0 41 41" />
            </Link>

            <Link href={PROJECT_TWITTER_URL} target="_blank">
                <SvgIcon htmlColor="white" component={Twitter} />
            </Link>

            <Link href={PROJECT_TELEGRAM_URL} target="_blank">
                <Telegram htmlColor="white" viewBox="0 0 22 22" />
            </Link>

            {/* <Link href="https://discord.gg" target="_blank">
                <SvgIcon htmlColor="white" viewBox="0 0 20 20" component={Discord} />
            </Link> */}

            {/* <Link href="https://medium.com" target="_blank">
                <SvgIcon htmlColor="white" viewBox="0 0 25 25" component={Medium} />
            </Link> */}
        </div>
    );
}
