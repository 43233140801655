import axios from "axios";
// import { StaticJsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
// import { Contract } from "ethers";
// import { Networks, TOKENS } from "src/constants";
// import { getBondCalculator } from "./bond-calculator";
// import { TimeTokenContract as ERC20Contract } from "../abi";

const cache: { [key: string]: number } = {};
const mcapCache: { [key: string]: number } = {};

const ids = ["pulsechain", "dai", "usd-coin", "tether", "ethereum"];
const symbols = ["PLS", "DAI", "USDC", "USDT", "ETH"];

export const loadTokenPrices = async () => {
    const url = `https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=${ids.join(",")}`;
    const { data } = await axios.get(url);

    ids.forEach((_id, idx) => {
        cache[symbols[idx]] = data[ids[idx]].usd;
    });
    cache["WPLS"] = cache["PLS"];
    cache["WETH"] = cache["ETH"];
};

export const loadMarketCap = async () => {
    const url = "https://api.coingecko.com/api/v3/coins/markets?ids=olympus&vs_currency=usd";
    const { data } = await axios.get(url);

    data.forEach((d: any) => (mcapCache[d.symbol.toUpperCase()] = d.market_cap));
};

export const getTokenPrice = (symbol: string): number => {
    return Number(cache[symbol]);
};

export const getMarketCap = (symbol: string): number => {
    return Number(mcapCache[symbol]);
};
